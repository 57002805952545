<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info4" class="pop_wrap">
            <div class="pop_inner">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">공지사항 삭제</p>
                <div class="cont_body">
                    <p class="mt20 f16 bold mb30 fc grey tac">공지사항 정보를 삭제 하시겠습니까?</p>
                    <p class="mt20 f14 mb30 fc grey tac">
                        "{{ item.title }}" 공지사항 정보를<br>
                        삭제하겠습니다.
                    </p>
                    <div class="tac mb30">
                        <button class="bg grey fc white button small" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg orange button small ml5" type="button" name="button" @click="onClickDeleteButton">삭제하기</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'NoticeRegister',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            item: {},
            doneRefresh: () => {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            if (!this.managerEmail) {
                this.showAlert('알림', '로그인 계정 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            if (params && params._value) {
                this.item = params._value.item || {}
                this.doneRefresh = params._value.refresh || (() => {})
            }
            if (!this.item.notify_id) {
                this.showAlert('알림', '공지사항 정보 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
        },
        async onClickDeleteButton() {
            await this.reqNoticeDelete()
        },
        async reqNoticeDelete() {
            let params = {
                email: this.managerEmail,
                notify_id: this.item.notify_id,
            }
            const response = await this.requestPost(Constants.API.notifyDelete, params)
            if (response) {
                this.showAlert('알림', '공지사항 삭제가 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
                this.doneRefresh()
            }
        },
    },
}
</script>