<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info" class="pop_wrap">
            <div class="pop_inner">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">{{ title }}</p>
                <div class="cont_body">
                    <p class="mt20 f14 mb30 fc grey">{{ message }}</p>
                    <div class="tac mb30">
                        <button class="bg grey fc white button small" type="button" name="button" @click="onClickCancelButton">{{ cancelLabel }}</button>
                        <button class="bg blue button small ml5" type="button" name="button" @click="onClickOkButton">{{ okLabel }}</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
export default {
    name: 'ConfirmModal',
    mixins: [],
    components: {},
    props: {},
    data() {
        return {
            title: '승인',
            message: '',
            okLabel: '확인',
            okFunc: () => {},
            cancelLabel: '취소',
            cancelFunc: () => {},
        }
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            if (params && params._value) {
                if (params._value.title) this.title = params._value.title
                if (params._value.message) this.message = params._value.message
                if (params._value.okLabel) this.okLabel = params._value.okLabel
                if (params._value.okFunc) this.okFunc = params._value.okFunc
                if (params._value.cancelLabel) this.cancelLabel = params._value.cancelLabel
                if (params._value.cancelFunc) this.cancelFunc = params._value.cancelFunc
            }
        },
        onClickOkButton() {
            this.$vfm.hide(this.$options.name)
            this.okFunc()
        },
        onClickCancelButton() {
            this.$vfm.hide(this.$options.name)
            this.cancelFunc()
        },
    },
}
</script>