import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { vfmPlugin } from 'vue-final-modal'
import { L10n } from '@syncfusion/ej2-base'
import { GridPlugin } from '@syncfusion/ej2-vue-grids'
import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars'
import { MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns'
import { CheckBoxPlugin } from '@syncfusion/ej2-vue-buttons'
import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor'

import '@/assets/stylesheet/admin.css'
import '@/assets/stylesheet/common.css'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(vfmPlugin({
    key: '$vfm',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer'
}))
app.use(GridPlugin)
app.use(DatePickerPlugin)
app.use(MultiSelectPlugin)
app.use(CheckBoxPlugin)
app.use(RichTextEditorPlugin)

L10n.load({
    'ko-KR': {
        'grid': {
            'EmptyRecord': '조회된 데이터가 없습니다.'
        }
    }
})

app.mount('#app')