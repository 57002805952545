<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'문의사항 관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageSubTitle }}</h2>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="divide3">
                                처리상태
                                <select class="wx150 mr40" name="" v-model="search.status" @change="reloadGridData">
                                    <optgroup label="*** 처리상태를 선택해주세요 ***">
                                        <option value="">전체</option>
                                        <option value="0">접수</option>
                                        <option value="1">답변완료</option>
                                    </optgroup>
                                </select>
                                <div class="wx500" style="display: flex;">
                                    등록일자
                                    <div class="wx170 ml10 mr10">
                                        <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.start_date"></ejs-datepicker>
                                    </div>
                                    <div class="wx170 ml10 mr40">
                                        <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.end_date"></ejs-datepicker>
                                    </div>
                                </div>
                                작성자<input type="text" name="" class="wx150 mr40" v-model="search.name" @keyup.enter="reloadGridData">
                                검색단어<input type="text" name="" class="wx150 mr40" v-model="search.keyword" @keyup.enter="reloadGridData">
                                <button class="bg blue button mid mr200" type="button" name="button" @click="reloadGridData">검색</button>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="mt35">
                    <div class="">
                        <div class="table_header">
                            <div class="dpinline fL">
                                <div class="f14 pt5" style="padding-top: 7px;">총 {{ listSize || '0' }}건</div>
                            </div>
                        </div>
                        <div class="table_wrap">
                            <ejs-grid
                                locale="ko-KR"
                                v-if="gridShow"
                                ref="grid"
                                :dataSource="data"
                                :allowPaging="true"
                                :pageSettings="pageSettings">
                                <e-columns>
                                    <e-column field="reg_date_ymd" headerText="등록일" textAlign="Center" width=150></e-column>
                                    <e-column field="title" headerText="제목" textAlign="Center" width=269></e-column>
                                    <e-column field="question" headerText="내용" textAlign="Center" width=757></e-column>
                                    <e-column field="reg_name" headerText="작성자" textAlign="Center" width=95></e-column>
                                    <e-column field="status_name" headerText="처리상태" textAlign="Center" width=117></e-column>
                                    <e-column headerText="관리" textAlign="Center" width=232 template="modify"></e-column>
                                </e-columns>
                                <template v-slot:modify="{data}">
                                    <a v-if="data.status === '0'" href="#" class="btn_open bg grey button xsmall" type="button" name="button" @click.prevent="onClickAnswerButton(data)">답변하기</a>
                                </template>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import InquiryAnswer from './modals/InquiryAnswer'

export default {
    name: 'InquiryPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        const _this = this
        class PagingListManager extends DataManager {
        }
        class PagingListAdaptor extends UrlAdaptor {
            beforeSend(dataManager, request, ajaxSettings) {
                request.withCredentials = false
                // _this.addPreloaderShowCount()
                try {
                    const page = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.currentPage
                    const row = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.pageSize
                    let param = {
                        page_num: page || 1,
                        row_num: row || 10,
                    }
                    if (_this.search.status) param.status = _this.search.status
                    if (_this.search.name) param.name = _this.search.name
                    if (_this.search.keyword) param.keyword = _this.search.keyword
                    if (_this.search.start_date) {
                        let start = moment(_this.search.start_date).format('YYYYMMDD')
                        if (start) param.start_date = start
                    }
                    if (_this.search.end_date) {
                        let end = moment(_this.search.end_date).format('YYYYMMDD')
                        if (end) param.end_date = end
                    }
                    const data = JSON.stringify(param)
                    ajaxSettings.data = data
                    ajaxSettings.options.data = data
                    // const sessionKey = _this.sessionKey
                    // request.setRequestHeader('session_id', sessionKey)
                } catch (e) {
                    // _this.subPreloaderShowCount()
                }
            }
            processResponse() {
                // _this.subPreloaderShowCount()
                let original = super.processResponse.apply(this, arguments)
                const list = []
                let count = 0
                if (original.resultCode === Constants.API_RESPONSE_CODE.invalidSessionId) {
                    _this.setManagerEmail(undefined)
                    _this.moveTo('Login')
                } else if (original.resultCode !== 200) {
                    _this.showAlert('Alert', original.resultMessage)
                } else {
                    count = Number(original.total_cnt || '0')
                    _.forEach(original.qna_list || [], function (item) {
                        item.status_name = item.status === '1' ? '답변완료' : item.status === '0' ? '접수' : '-'
                        item.question_html = (item.question || '').replaceAll('\n', '<br/>')
                        if (item.reg_dt) {
                            item.reg_date_ymd = _this.dateTimeSetFormat(item.reg_dt, 'YYYY-MM-DD')
                            item.reg_date_full = _this.dateTimeSetFormat(item.reg_dt)
                        } else {
                            item.reg_date_ymd = '-'
                            item.reg_date_full = '-'
                        }
                        list.push(item)
                    })
                }
                _this.listSize = count
                return { result: list, count: count }
            }
        }
        return {
            pageTitle: '문의사항',
            pageSubTitle: '문의사항 관리',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                status: '',
                name: '',
                keyword: '',
                start_date: '',
                end_date: '',
            },
            pageSettings: { pageSize: 10, pageCount: 5 },
            data: new PagingListManager({
                url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.qnaList}`,
                adaptor: new PagingListAdaptor(),
                crossDomain: true
            }),
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        reloadGridData() {
            const _this = this
            this.gridShow = false
            setTimeout(function () {
                _this.gridShow = true
            }, 50)
        },
        onClickAnswerButton(data) {
            this.$vfm.show({ component: InquiryAnswer }, {item: data, refresh: this.$refs.grid.refresh})
        },
    },
}
</script>