import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import account from './modules/account'

const store = createStore({
    modules: {
        account
    },
    plugins: [createPersistedState({
        key: 'account',
        paths: ['account'],
        storage: window.sessionStorage,
    })],
    state () {
        return {
            preloaderShowCount: 0,
            preloaderTitle: 'Loading...',
        }
    },
    getters: {
        preloaderShowCount: state => state.preloaderShowCount,
    },
    mutations: {
        addPreloaderShowCount: (state, title) => {
            state.preloaderShowCount++
            state.preloaderTitle = title || 'Loading...'
        },
        subPreloaderShowCount: (state) => {
            if (state.preloaderShowCount > 0) state.preloaderShowCount--
        },
        setPreloaderTitle: (state, key) => {
            state.preloaderTitle = key || 'Loading...'
        },
    },
})

export default store