import moment from 'moment'

export const CommonFunctions = {
    computed: {
    },
    methods: {
        moveTo(page, params = {}, type = 'params') {
            const value = { name: page }
            if (Object.keys(params).length !== 0) value[type] = params
            if (page && page !== this.$route.name) this.$router.push(value)
        },
        isEmailValid(email) {
            const reg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            return email === '' ? false : !!reg.test(email)
        },
        dateTimeSetFormat(dt, format = 'YYYY-MM-DD HH:mm:ss', receiveFormat = 'YYYYMMDDHHmmss') {
            return moment(dt, receiveFormat).format(format)
        },
        getFormDataLength(data) {
            if (!data || !data.forEach) return 0
            try {
                let count = 0
                data.forEach(() => {
                    count++
                })
                return count
            } catch (e) {
                return -1
            }
        },
        getObjectLength(data) {
            if (!data) return 0
            try {
                return Object.keys(data).length
            } catch (e) {
                return -1
            }
        }
    },
}