<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'APP버전관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }}</h2>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="">
                                <div class="divide3">
                                    <p class="wx180">안드로이드 APP 버전 정보</p>
                                    <input type="text" name="" class="wx150 mr15" :placeholder="androidVer" v-model="androidVer">
                                    <button class="bg blue button mid" type="button" name="button" @click="onClickAndroidUpdateButton">수정하기</button>
                                </div>
                                <div class="divide3">
                                    <p class="wx180">아이폰 APP 버전 정보</p>
                                    <input type="text" name="" class="wx150 mr15" :placeholder="iosVer" v-model="iosVer">
                                    <button class="bg blue button mid" type="button" name="button" @click="onClickIosUpdateButton">수정하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="mt35">

                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'

export default {
    name: 'VersionPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
    },
    data() {
        return {
            pageTitle: 'APP버전관리',
            item: {},
            androidVer: '',
            iosVer: '',
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
        this.reqAppVersion()
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async reqAppVersion() {
            this.item = {}
            this.androidVer = ''
            this.iosVer = ''
            const response = await this.requestGet(Constants.API.appInfo)
            if (response) {
                this.item = _.cloneDeep(response)
                this.androidVer = response.android_ver || ''
                this.iosVer = response.ios_ver || ''
            }
        },
        async onClickAndroidUpdateButton() {
            if (this.androidVer === this.item.android_ver) return
            await this.reqAndroidUpdate()
        },
        async reqAndroidUpdate() {
            let params = {
                email: this.managerEmail,
                android_ver: this.androidVer,
            }
            const response = await this.requestPost(Constants.API.appUpdate, params)
            if (response) {
                this.showAlert('알림', '안드로이드 APP 버전 수정이 완료되었습니다.')
                await this.reqAppVersion()
            }
        },
        async onClickIosUpdateButton() {
            if (this.iosVer === this.item.ios_ver) return
            await this.reqIosUpdate()
        },
        async reqIosUpdate() {
            let params = {
                email: this.managerEmail,
                ios_ver: this.iosVer,
            }
            const response = await this.requestPost(Constants.API.appUpdate, params)
            if (response) {
                this.showAlert('알림', '아이폰 APP 버전 수정이 완료되었습니다.')
                this.modifyMode = false
                await this.reqAppVersion()
            }
        },
    },
}
</script>