<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'정보관리'" :page="pageTitle"/>
                    <div class="title divide3">
                        <h2 class="h2">{{ pageTitle }}</h2>
                    </div>
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="divide2">
                    <div class="cont_left02">
                        <div class="mt35">
                            <div class="table_header">
                                <div class="dpinline fL">
                                    <div class="f14 pt5" style="padding-top: 7px;">총 {{ listData.length || '0' }}건</div>
                                </div>
                            </div>
                            <div class="table_wrap">
                                <ejs-grid
                                    locale="ko-KR"
                                    v-if="gridShow"
                                    ref="grid"
                                    :dataSource="listData"
                                    :allowPaging="true"
                                    :pageSettings="pageSettings">
                                    <e-columns>
                                        <e-column headerText="아이콘" textAlign="Center" width=90 template="thumbImage"></e-column>
                                        <e-column field="species_type" headerText="생물유형" textAlign="Center" width=102></e-column>
                                        <e-column field="order" headerText="노출순서" textAlign="Center" width=102></e-column>
                                        <e-column field="show_flag_name" headerText="노출여부" textAlign="Center" width=102></e-column>
                                        <e-column field="reg_date_ymd" headerText="등록일" textAlign="Center" width=131></e-column>
                                        <e-column field="mod_date_ymd" headerText="수정일" textAlign="Center" width=131></e-column>
                                        <e-column headerText="관리" textAlign="Center" width=202 template="modify"></e-column>
                                    </e-columns>
                                    <template v-slot:thumbImage="{data}">
                                        <img class="pointer" style="width: 44px; height: 44px; background-position: center center; background-repeat: no-repeat;" v-if="data.pic_url" :src="data.pic_url" :alt="data.species_type" @click.prevent="showImageViewer(data.species_type)">
                                    </template>
                                    <template v-slot:modify="{data}">
                                        <button class="bg grey button xsmall" type="button" name="button" @click="onClickModifyStartButton(data)">수정하기</button>
                                    </template>
                                </ejs-grid>
                            </div>
                        </div>
                    </div>
                    <div class="cont_right02 table_wrap">
                        <p class="f20 mb20 mt30">생물유형 등록</p>
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="25%">
                                <col width="25%">
                                <col width="25%">
                                <col width="25%">
                            </colgroup>
                            <tr>
                                <th>생물유형</th>
                                <td colspan="3"><input type="text" name="" v-model="registerItem.speciesType"></td>
                            </tr>
                            <tr>
                                <th>표시 아이콘</th>
                                <td>
                                    <img class="pointer" v-if="registerItem.pictureUrl" :alt="registerItem.species_type" style="width: 44px; height: 44px" :src="registerItem.pictureUrl" @click.prevent="showImageViewer(registerItem.pictureUrl)">
                                    <input ref="regNormalFileUpload" type="file" accept="image/*" @change="handleRegNormalFileChange" hidden>
                                </td>
                                <td colspan="2">
                                    <button class="bg blue button mid" type="button" name="button" @click="chooseRegNormalFile">아이콘 선택하기</button>
                                </td>
                            </tr>
                            <tr>
                                <th>선택시 아이콘</th>
                                <td>
                                    <img class="pointer" v-if="registerItem.selPictureUrl" :alt="registerItem.species_type" style="width: 44px; height: 44px" :src="registerItem.selPictureUrl" @click.prevent="showImageViewer(registerItem.selPictureUrl)">
                                    <input ref="regSelectFileUpload" type="file" accept="image/*" @change="handleRegSelectFileChange" hidden>
                                </td>
                                <td colspan="2"><button class="bg blue button mid" type="button" name="button" @click="chooseRegSelectFile">아이콘 선택하기</button></td>
                            </tr>
                            <tr>
                                <th>노출여부</th>
                                <td>
                                    <div class="dpinline">
                                        <form class="dpinline ml10">
                                            <label class="radiolabel mr15" for="radio01_t">노출<input type="radio" id="radio01_t" name="radio01" value="1" v-model="registerItem.showFlag"><span class="checkmark"></span></label>
                                            <label class="radiolabel" for="radio01_f">감추기<input type="radio" id="radio01_f" name="radio01" value="0" v-model="registerItem.showFlag"><span class="checkmark"></span></label>
                                        </form>
                                    </div>
                                </td>
                                <th>노출순서</th>
                                <td><input type="number" name="" v-model="registerItem.order"></td>
                            </tr>
                        </table>
                        <div class="tac">
                            <button class="bg blue button mid mt20" type="button" name="button" @click="onClickRegisterButton">등록하기</button>
                        </div>
                        <template v-if="modifyItem.species_type">
                            <p class="f20 mb20">생물유형 수정</p>
                            <table class="tbl_basic">
                                <colgroup>
                                    <col width="25%">
                                    <col width="25%">
                                    <col width="25%">
                                    <col width="25%">
                                </colgroup>
                                <tr>
                                    <th>생물유형</th>
                                    <td colspan="3">{{ modifyItem.species_type }}</td>
                                </tr>
                                <tr>
                                    <th>표시 아이콘</th>
                                    <td>
                                        <img class="pointer" v-if="modifyItem.pic_url" :alt="modifyItem.species_type" style="width: 44px; height: 44px" :src="modifyItem.pic_url" @click.prevent="showImageViewer(modifyItem.pic_url)">
                                        <input ref="modNormalFileUpload" type="file" accept="image/*" @change="handleModNormalFileChange" hidden>
                                    </td>
                                    <td colspan="2"><button class="bg blue button mid" type="button" name="button" @click="chooseModNormalFile">아이콘 선택하기</button></td>
                                </tr>
                                <tr>
                                    <th>선택시 아이콘</th>
                                    <td>
                                        <img class="pointer" v-if="modifyItem.sel_pic_url" :alt="modifyItem.species_type" style="width: 44px; height: 44px" :src="modifyItem.sel_pic_url" @click.prevent="showImageViewer(modifyItem.sel_pic_url)">
                                        <input ref="modSelectFileUpload" type="file" accept="image/*" @change="handleModSelectFileChange" hidden>
                                    </td>
                                    <td colspan="2"><button class="bg blue button mid" type="button" name="button" @click="chooseModSelectFile">아이콘 선택하기</button></td>
                                </tr>
                                <tr>
                                    <th>노출여부</th>
                                    <td>
                                        <div class="dpinline">
                                            <form class="dpinline ml10">
                                                <label class="radiolabel mr15" for="radio02_t">노출<input type="radio" id="radio02_t" name="radio02" value="1" v-model="modifyItem.show_flag"><span class="checkmark"></span></label>
                                                <label class="radiolabel" for="radio02_f">감추기<input type="radio" id="radio02_f" name="radio02" value="0" v-model="modifyItem.show_flag"><span class="checkmark"></span></label>
                                            </form>
                                        </div>
                                    </td>
                                    <th>노출순서</th>
                                    <td><input type="number" name="" v-model="modifyItem.order"></td>
                                </tr>
                            </table>
                            <div class="tac">
                                <button class="bg grey button mid mt20" type="button" name="button" @click="onClickModifyDoneButton">수정하기</button>
                            </div>
                        </template>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'

export default {
    name: 'InfoCategoryPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        return {
            pageTitle: '생물유형 관리',
            gridShow: true,
            pageSettings: { pageSize: 10, pageCount: 5 },
            listData: [],
            registerItem: {
                speciesType: '',
                picture: '',
                pictureUrl: '',
                selPicture: '',
                selPictureUrl: '',
                order: '',
                showFlag: '',
            },
            modifyOriginItem: {},
            modifyItem: {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
        this.reqSpeciesList();
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async reqSpeciesList() {
            this.modifyOriginItem = {}
            this.modifyItem = {}
            const _this = this
            const response = await this.requestGet(Constants.API.speciesList)
            if (response) {
                this.listData.length = 0
                this.listData.push(..._.orderBy(_.forEach(response.species_list || [], species => {
                    if (species.reg_date) {
                        species.reg_date_ymd = _this.dateTimeSetFormat(species.reg_date, 'YYYY-MM-DD')
                        species.reg_date_full = _this.dateTimeSetFormat(species.reg_date)
                    } else {
                        species.reg_date_ymd = '-'
                        species.reg_date_full = '-'
                    }
                    if (species.mod_date) {
                        species.mod_date_ymd = _this.dateTimeSetFormat(species.mod_date, 'YYYY-MM-DD')
                        species.mod_date_full = _this.dateTimeSetFormat(species.mod_date)
                    } else {
                        species.mod_date_ymd = '-'
                        species.mod_date_full = '-'
                    }
                    if (species.show_flag) {
                        species.show_flag_name = species.show_flag === '1' ? '노출' : species.show_flag === '0' ? '감추기' : '-'
                    } else {
                        species.show_flag_name = '-'
                    }
                }), 'order'))
                this.gridShow = false
                setTimeout(function () {
                    _this.gridShow = true
                }, 50)
            }
        },
        chooseRegNormalFile() {
            this.$refs.regNormalFileUpload.click()
        },
        handleRegNormalFileChange(e) {
            let file = e.target.files[0]
            this.registerItem.picture = file
            if (this.registerItem.picture) this.registerItem.pictureUrl = URL.createObjectURL(this.registerItem.picture)
            else this.registerItem.pictureUrl = ''
        },
        chooseRegSelectFile() {
            this.$refs.regSelectFileUpload.click()
        },
        handleRegSelectFileChange(e) {
            let file = e.target.files[0]
            this.registerItem.selPicture = file
            if (this.registerItem.selPicture) this.registerItem.selPictureUrl = URL.createObjectURL(this.registerItem.selPicture)
            else this.registerItem.selPictureUrl = ''
        },
        chooseModNormalFile() {
            this.$refs.modNormalFileUpload.click()
        },
        handleModNormalFileChange(e) {
            let file = e.target.files[0]
            this.modifyItem.picture = file
            if (this.modifyItem.picture) this.modifyItem.pic_url = URL.createObjectURL(this.modifyItem.picture)
            else this.modifyItem.pic_url = this.modifyOriginItem.pic_url || ''
        },
        chooseModSelectFile() {
            this.$refs.modSelectFileUpload.click()
        },
        handleModSelectFileChange(e) {
            let file = e.target.files[0]
            this.modifyItem.selPicture = file
            if (this.modifyItem.selPicture) this.modifyItem.sel_pic_url = URL.createObjectURL(this.modifyItem.selPicture)
            else this.modifyItem.sel_pic_url = this.modifyOriginItem.sel_pic_url || ''
        },
        onClickModifyStartButton(data) {
            // this.modifyOriginItem = _.cloneDeep(data)
            this.modifyOriginItem.order = data.order || ''
            this.modifyOriginItem.pic_url = data.pic_url || ''
            this.modifyOriginItem.sel_pic_url = data.sel_pic_url || ''
            this.modifyOriginItem.show_flag =  data.show_flag || ''
            this.modifyOriginItem.species_type = data.species_type || ''
            // this.modifyItem = _.cloneDeep(data)
            this.modifyItem.order = data.order || ''
            this.modifyItem.pic_url = data.pic_url || ''
            this.modifyItem.sel_pic_url = data.sel_pic_url || ''
            this.modifyItem.show_flag =  data.show_flag || ''
            this.modifyItem.species_type = data.species_type || ''
            this.modifyItem.picture = data.picture || ''
            this.modifyItem.selPicture = data.selPicture || ''
        },
        async onClickRegisterButton() {
            if (!this.registerItem.speciesType) {
                this.showAlert('알림', '생물유형을 입력해주세요.')
                return
            }
            if (!this.registerItem.picture) {
                this.showAlert('알림', '표시 아이콘을 선택해주세요.')
                return
            }
            if (!this.registerItem.selPicture) {
                this.showAlert('알림', '선택시 아이콘을 선택해주세요.')
                return
            }
            if (this.registerItem.showFlag === undefined || this.registerItem.showFlag === '') {
                this.showAlert('알림', '노출여부를 선택해주세요.')
                return
            }
            if (this.registerItem.order === undefined || this.registerItem.order === '') {
                this.showAlert('알림', '노출순서를 입력해주세요.')
                return
            }
            await this.reqSpeciesRegister()
        },
        async reqSpeciesRegister() {
            if (!this.managerEmail) return
            let data = new FormData()
            data.append('email', this.managerEmail)
            data.append('species_type', this.registerItem.speciesType)
            data.append('picture', this.registerItem.picture)
            data.append('sel_picture', this.registerItem.selPicture)
            data.append('order', this.registerItem.order)
            data.append('show_flag', this.registerItem.showFlag)
            const response = await this.requestFormData(Constants.API.speciesRegister, data)
            if (response) {
                this.showAlert('알림', '생물유형 등록이 완료되었습니다.')
                this.registerItem.speciesType = ''
                this.registerItem.picture = ''
                this.registerItem.pictureUrl = ''
                this.registerItem.selPicture = ''
                this.registerItem.selPictureUrl = ''
                this.registerItem.order = ''
                this.registerItem.showFlag = ''
                await this.reqSpeciesList()
            }
        },
        async onClickModifyDoneButton() {
            if (!this.modifyOriginItem.species_type) return
            if (this.modifyItem.order === undefined || this.modifyItem.order === '') {
                this.showAlert('알림', '노출순서를 입력해주세요.')
                return
            }
            await this.reqSpeciesModify()
        },
        async reqSpeciesModify() {
            if (!this.managerEmail) return
            let data = new FormData()
            data.append('email', this.managerEmail)
            data.append('species_type', this.modifyOriginItem.species_type)
            if (this.modifyItem.picture) data.append('picture', this.modifyItem.picture)
            if (this.modifyItem.selPicture) data.append('picture', this.modifyItem.selPicture)
            if (this.modifyItem.order !== this.modifyOriginItem.order) data.append('order', this.modifyItem.order)
            if (this.modifyItem.show_flag !== this.modifyOriginItem.show_flag) data.append('show_flag', this.modifyItem.show_flag)
            if (this.getFormDataLength(data) === 2) {
                return
            }
            const response = await this.requestFormData(Constants.API.speciesModify, data)
            if (response) {
                this.showAlert('알림', '생물유형 수정이 완료되었습니다.')
                await this.reqSpeciesList()
            }
        },
    },
}
</script>