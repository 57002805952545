import { Alert, Confirm, ImageViewer } from '@/components/modals'

export const ModalFunctions = {
    methods: {
        showAlert(title = '알림', message, okLabel = '확인', okFunc) {
            this.$vfm.show({ component: Alert }, {title: title, message: message, okLabel: okLabel, okFunc: okFunc})
        },
        showConfirm(title = '승인', message, okLabel = '확인', okFunc, cancelLabel = '취소', cancelFunc) {
            this.$vfm.show({ component: Confirm }, {title: title, message: message, okLabel: okLabel, okFunc: okFunc, cancelLabel: cancelLabel, cancelFunc: cancelFunc})
        },
        showImageViewer(imageSrc) {
            if (!imageSrc) this.showAlert('알림', '이미지 URL 이 잘못되었습니다.')
            this.$vfm.show({ component: ImageViewer }, {src: imageSrc})
        },
    }
}