<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'도움말 관리'" :page="pageTitle"/>
                    <div class="title divide2">
                        <h2 class="h2">{{ pageSubTitle }}</h2>
                        <a href="#" class="btn_open bg blue button xsmall ml700" type="button" name="button" @click.prevent="onClickRegisterButton">+ 도움말 등록</a>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="divide3">
                                검색단어<input type="text" name="" v-model="search.keyword" data-pcwid="200" class="wx200 mr40" @keyup.enter="reloadGridData">
                                <div class="wx500" style="display: flex;">
                                    등록일자
                                    <div class="wx170 ml10 mr10">
                                        <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.start_date"></ejs-datepicker>
                                    </div>
                                    <div class="wx170 ml10 mr40">
                                        <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.end_date"></ejs-datepicker>
                                    </div>
                                </div>
                                <button class="bg blue button mid mr200" type="button" name="button" @click="reloadGridData">검색</button>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="mt35">
                    <div class="">
                        <div class="table_header">
                            <div class="dpinline fL">
                                <div class="f14 pt5" style="padding-top: 7px;">총 5건</div>
                            </div>
                        </div>
                        <div class="table_wrap">
                            <ejs-grid
                                locale="ko-KR"
                                v-if="gridShow"
                                ref="grid"
                                :dataSource="data"
                                :allowPaging="true"
                                :pageSettings="pageSettings">
                                <e-columns>
                                    <e-column field="reg_date_ymd" headerText="등록일" textAlign="Center" width=122></e-column>
                                    <e-column field="period" headerText="표시기간" textAlign="Center" width=246></e-column>
                                    <e-column field="title" headerText="제목" textAlign="Center" width=259></e-column>
                                    <e-column field="contents" headerText="내용" textAlign="Center" width=781></e-column>
                                    <e-column field="reg_name" headerText="작성자" textAlign="Center" width=88></e-column>
                                    <e-column headerText="관리" textAlign="Center" width=124 template="modify"></e-column>
                                </e-columns>
                                <template v-slot:modify="{data}">
                                    <div class="divide2">
                                        <a href="" @click.prevent="onClickViewButton(data)"><img src="@/assets/images/chat.png" alt="공지 상세" style="width: 24px; height: 24px"></a>
                                        <a href="" @click.prevent="onClickModifyStartButton(data)" class="btn_open"><img src="@/assets/images/create.png" alt="공지 수정" style="width: 24px; height: 24px"></a>
                                        <a href="" @click.prevent="onClickDeleteButton(data)" class="btn_open"><img src="@/assets/images/delete_outline.png" alt="공지 삭제" style="width: 24px; height: 24px"></a>
                                    </div>
                                </template>
                            </ejs-grid>
                        </div>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import HelpRegister from './modals/HelpRegister'
import HelpView from './modals/HelpView'
import HelpModify from './modals/HelpModify'
import HelpDelete from './modals/HelpDelete'

export default {
    name: 'HelpPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        const _this = this
        class PagingListManager extends DataManager {
        }
        class PagingListAdaptor extends UrlAdaptor {
            beforeSend(dataManager, request, ajaxSettings) {
                request.withCredentials = false
                // _this.addPreloaderShowCount()
                try {
                    const page = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.currentPage
                    const row = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.pageSize
                    let param = {
                        page_num: page || 1,
                        row_num: row || 10,
                    }
                    if (_this.search.keyword) param.keyword = _this.search.keyword
                    if (_this.search.start_date) {
                        let start = moment(_this.search.start_date).format('YYYYMMDD')
                        if (start) param.start_date = start
                    }
                    if (_this.search.end_date) {
                        let end = moment(_this.search.end_date).format('YYYYMMDD')
                        if (end) param.end_date = end
                    }
                    const data = JSON.stringify(param)
                    ajaxSettings.data = data
                    ajaxSettings.options.data = data
                    // const sessionKey = _this.sessionKey
                    // request.setRequestHeader('session_id', sessionKey)
                } catch (e) {
                    // _this.subPreloaderShowCount()
                }
            }
            processResponse() {
                // _this.subPreloaderShowCount()
                let original = super.processResponse.apply(this, arguments)
                const list = []
                let count = 0
                if (original.resultCode === Constants.API_RESPONSE_CODE.invalidSessionId) {
                    _this.setManagerEmail(undefined)
                    _this.moveTo('Login')
                } else if (original.resultCode !== 200) {
                    _this.showAlert('Alert', original.resultMessage)
                } else {
                    count = Number(original.total_cnt || '0')
                    _.forEach(original.help_list || [], function (item) {
                        item.status_name = item.status === '1' ? '활성화' : item.status === '0' ? '비활성화' : '-'
                        item.contents_html = (item.contents || '').replaceAll('\n', '<br/>')
                        if (item.reg_date) {
                            item.reg_date_ymd = _this.dateTimeSetFormat(item.reg_date, 'YYYY-MM-DD')
                            item.reg_date_full = _this.dateTimeSetFormat(item.reg_date)
                        } else {
                            item.reg_date_ymd = '-'
                            item.reg_date_full = '-'
                        }
                        if (item.start_date) {
                            item.start_date_ymd = _this.dateTimeSetFormat(item.start_date, 'YYYY-MM-DD')
                        } else {
                            item.start_date_ymd = '-'
                        }
                        if (item.end_date) {
                            item.end_date_ymd = _this.dateTimeSetFormat(item.end_date, 'YYYY-MM-DD')
                        } else {
                            item.end_date_ymd = ''
                        }
                        if (item.start_date && item.end_date) {
                            item.period = _this.dateTimeSetFormat(item.start_date, 'YYYY-MM-DD') + ' ~ ' + _this.dateTimeSetFormat(item.end_date, 'YYYY-MM-DD')
                        } else {
                            item.period = '-'
                        }
                        list.push(item)
                    })
                }
                _this.listSize = count
                return { result: list, count: count }
            }
        }
        return {
            pageTitle: '도움말',
            pageSubTitle: '도움말 관리',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                keyword: '',
                start_date: '',
                end_date: '',
            },
            pageSettings: { pageSize: 10, pageCount: 5 },
            data: new PagingListManager({
                url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.helpList}`,
                adaptor: new PagingListAdaptor(),
                crossDomain: true
            }),
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        reloadGridData() {
            const _this = this
            this.gridShow = false
            setTimeout(function () {
                _this.gridShow = true
            }, 50)
        },
        onClickRegisterButton() {
            this.$vfm.show({ component: HelpRegister }, {refresh: this.reloadGridData})
        },
        onClickViewButton(data) {
            this.$vfm.show({ component: HelpView }, {item: data})
        },
        onClickModifyStartButton(data) {
            this.$vfm.show({ component: HelpModify }, {item: data, refresh: this.$refs.grid.refresh})
        },
        onClickDeleteButton(data) {
            this.$vfm.show({ component: HelpDelete }, {item: data, refresh: this.$refs.grid.refresh})
        },
    },
}
</script>