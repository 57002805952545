<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info2" class="pop_wrap">
            <div class="pop_inner04">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">도움말 보기</p>
                <div class="cont_body mt30">
                    <div class="table_wrap">
                        <table class="table_list">
                            <tr class="head">
                                <th>{{ item.title }}</th>
                            </tr>
                            <tr>
                                <td class="tal">등록일자 : {{ item.reg_date_full }}</td>
                            </tr>
                            <tr>
                                <td class="tal">공지기간 : {{ item.start_date_ymd }} {{ item.end_date_ymd ? '~' : '' }} {{ item.end_date_ymd }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="notice_area" v-html="item.contents_html"></div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="tac mb20">
                        <button class="bg grey fc white button mid mt30" type="button" name="button" @click="close">닫기</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import {mapGetters} from 'vuex'

export default {
    name: 'HelpView',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            item: {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            this.$vfm.hide(this.$options.name)
            if (!this.managerEmail) {
                this.showAlert('알림', '로그인 계정 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            if (params && params._value) {
                this.item = params._value.item || {}
            }
            if (!this.item.help_id) {
                this.showAlert('알림', '도움말 정보 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
        },
    },
}
</script>