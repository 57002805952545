export default Object.freeze({
    API: {
        accountRegister: '/account/register',
        accountLogin: '/account/login',
        accountReset: '/account/reset',

        memberList: '/member/list',
        memberGrade: '/member/grade',
        memberStatus: '/member/status',

        managerList: '/list',
        managerModify: '/modify',

        postList: '/post/list',
        postStatus: '/post/status',

        teamList: '/team/list',
        teamApprove: '/team/approve',
        teamRegister: '/team/register',
        teamModify: '/team/modify',

        speciesList: '/species/list',
        speciesRegister: '/species/register',
        speciesModify: '/species/modify',

        gradeList: '/grade/list',
        gradeRegister: '/grade/register',
        gradeModify: '/grade/modify',

        notifyList: '/notify/list',
        notifyRegister: '/notify/register',
        notifyModify: '/notify/modify',
        notifyDelete: '/notify/delete',

        helpList: '/help/list',
        helpRegister: '/help/register',
        helpModify: '/help/modify',
        helpDelete: '/help/delete',

        qnaList: '/qna/list',
        qnaAnswer: '/qna/answer',

        termsList: '/terms/list',
        termsRegister: '/terms/register',
        termsModify: '/terms/modify',
        termsDelete: '/terms/delete',

        statInfo: '/stat/info',
        statExcel: '/stat/excel',

        appInfo: '/app/info',
        appUpdate: '/app/update',

        pwdSetting: '/pwd/setting',
    },
    API_RESPONSE_CODE: {
        success: 200,
        notFound: 404,
        invalidSessionId: 123456,
    }
})