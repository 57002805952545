<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'통계'"/>
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }} {{ title ? ` - ${title}` : '' }}</h2>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="divide3">
                                <div class="">
                                    통계타입
                                    <select class="wx150 mr40" name="" v-model="search.stat_type" @change="reqStatisticsInfo">
                                        <optgroup label="*** 통계타입을 선택해주세요 ***">
                                            <option value="REGISTER">가입통계</option>
                                            <option value="POST">관찰기록 등록 통계</option>
                                        </optgroup>
                                    </select>
                                </div>
                                <div class="">
                                    통계기간
                                    <select class="wx150 mr40" name="" v-model="search.stat_period" @change="reqStatisticsInfo">
                                        <optgroup label="*** 통계기간을 선택해주세요 ***">
                                            <option value="DAY">일별</option>
                                            <option value="MONTH">월별</option>
                                            <option value="YEAR">연별</option>
                                        </optgroup>
                                    </select>
                                </div>
                                <div class="wx500" style="display: flex;">
                                    검색기간
                                    <div class="wx170 ml10 mr10">
                                        <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.start_date" :showClearButton="false"></ejs-datepicker>
                                    </div>
                                    <div class="wx170 ml10 mr40">
                                        <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.end_date" :showClearButton="false"></ejs-datepicker>
                                    </div>
                                </div>
                                <button class="bg blue button mid mr5" type="button" name="button" @click="reqStatisticsInfo">검색</button>
                                <button class="bg grey button mid mr200" type="button" name="button" @click="onClickExcelButton">EXCEL다운로드</button>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="mt35">
                    <div class="hx600" id="chartContainer"></div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from '@/common/axios'
import moment from 'moment'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import * as echarts from 'echarts'

export default {
    name: 'StatisticsPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
    },
    data() {
        return {
            pageTitle: '통계',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                stat_type: 'REGISTER',
                stat_period: 'DAY',
                start_date: '',
                end_date: '',
            },
            title: '',
            labelList: [],
            valueList: [],
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
        this.search.start_date = this.dateTimeSetFormat(moment().subtract(1, 'month'), 'YYYY-MM-DD')
        this.search.end_date = this.dateTimeSetFormat(moment(), 'YYYY-MM-DD')
        this.reqStatisticsInfo()
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async reqStatisticsInfo() {
            let start = this.dateTimeSetFormat(this.search.start_date, 'YYYYMMDD', 'YYYY-MM-DD')
            let end = this.dateTimeSetFormat(this.search.end_date, 'YYYYMMDD', 'YYYY-MM-DD')
            if (!start || !end) {
                this.showAlert('알림', '검색기간을 선택해주세요')
                return
            }
            let params = {
                stat_type: this.search.stat_type,
                stat_period: this.search.stat_period,
                start_date: start,
                end_date: end,
            }
            const response = await this.requestPost(Constants.API.statInfo, params)
            if (response) {
                console.log(response.stat_list)
                this.title = response.stat_list?.label || ''
                const valueList = _.orderBy(response.stat_list?.value_list || [], 'stat_date')
                this.labelList = _.map(valueList, 'stat_date')
                this.valueList = _.map(valueList, 'stat_val')
                this.drawChart()
            }
        },
        async onClickExcelButton() {
            await this.reqExcelDownload()
        },
        async reqExcelDownload() {
            let start = this.dateTimeSetFormat(this.search.start_date, 'YYYYMMDD', 'YYYY-MM-DD')
            let end = this.dateTimeSetFormat(this.search.end_date, 'YYYYMMDD', 'YYYY-MM-DD')
            if (!start || !end) {
                this.showAlert('알림', '검색기간을 선택해주세요')
                return
            }
            let params = {
                stat_type: this.search.stat_type,
                stat_period: this.search.stat_period,
                start_date: start,
                end_date: end,
            }
            const result = await axios.post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.statExcel}`, params, { responseType: 'blob' })
            if (!result.data) {
                return
            }
            const url = window.URL.createObjectURL(new Blob([result.data], {type: result.headers['content-type']}))
            const link = document.createElement('a');
            link.href = url;
            let fileName = `Statistics_${start}_${end}.xlsx`
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        drawChart() {
            let chartDom = document.getElementById('chartContainer')
            let myChart = echarts.init(chartDom)
            if (myChart) myChart.dispose()
            myChart = echarts.init(chartDom)
            let option = {
                xAxis: {
                    type: 'category',
                    data: this.labelList,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        data: this.valueList,
                        type: 'line'
                    }
                ],
            }
            myChart.setOption(option)

            window.onresize = () => {
                myChart.resize()
            }
        },
    },
}
</script>