<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'정보관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageSubTitle }}</h2>
                    </div>
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="divide2">
                    <!-- search box -->
                    <div class="cont_left02">
                        <div class="mt35">
                            <div class="table_header">
                                <div class="dpinline fL">
                                    <div class="f14 pt5" style="padding-top: 7px;">총 4건</div>
                                </div>
                            </div>
                            <div class="table_wrap">
                                <ejs-grid
                                    locale="ko-KR"
                                    v-if="gridShow"
                                    ref="grid"
                                    :dataSource="listData"
                                    :allowPaging="true"
                                    :pageSettings="pageSettings">
                                    <e-columns>
                                        <e-column field="level" headerText="레벨이름" textAlign="Center" width=83></e-column>
                                        <e-column field="like_cnt" headerText="공감갯수" textAlign="Center" width=83></e-column>
                                        <e-column field="reply_cnt" headerText="댓글갯수" textAlign="Center" width=83></e-column>
                                        <e-column field="post_cnt" headerText="관찰글갯수" textAlign="Center" width=99></e-column>
                                        <e-column field="pic_cnt" headerText="사진갯수" textAlign="Center" width=83></e-column>
                                        <e-column field="order" headerText="순서" textAlign="Center" width=51></e-column>
                                        <e-column field="reg_date_ymd" headerText="등록일" textAlign="Center" width=106></e-column>
                                        <e-column field="mod_date_ymd" headerText="수정일" textAlign="Center" width=106></e-column>
                                        <e-column headerText="관리" textAlign="Center" width=165 template="modify"></e-column>
                                    </e-columns>
                                    <template v-slot:modify="{data}">
                                        <button class="bg grey button xsmall" type="button" name="button" @click="onClickModifyStartButton(data)">수정하기</button>
                                    </template>
                                </ejs-grid>
                            </div>
                        </div>
                    </div>
                    <div class="cont_right02 table_wrap">
                        <p class="f20 mb20 mt30">생물과학자 등급 수정</p>
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="25%">
                                <col width="75%">
                            </colgroup>
                            <tr>
                                <th>레벨이름</th><td><input type="text" name="" v-model="registerItem.level" placeholder="Level1"></td>
                            </tr>
                            <tr>
                                <th>등업기준</th>
                                <td>
                                    <input type="number" name="" v-model="registerItem.likeCnt" class="wx40 mr10" placeholder="1">공감
                                    <input type="number" name="" v-model="registerItem.replyCnt" class="wx40 mr10 ml10" placeholder="1">댓글
                                    <input type="number" name="" v-model="registerItem.postCnt" class="wx40 mr10 ml10" placeholder="1">관찰수
                                    <input type="number" name="" v-model="registerItem.picCnt" class="wx40 mr10 ml10" placeholder="1">사진수
                                    <span class="bold ml10">만족 시 자동등업</span>
                                </td>
                            </tr>
                            <tr>
                                <th>등급순서</th><td><input type="number" name="" v-model="registerItem.order"></td>
                            </tr>
                        </table>
                        <div class="tac">
                            <button class="bg blue button mid mt20" type="button" name="button" @click="onClickRegisterButton">등록하기</button>
                        </div>
                        <template v-if="modifyItem.level">
                            <p class="f20 mb20">생물유형 수정</p>
                            <table class="tbl_basic">
                                <colgroup>
                                    <col width="25%">
                                    <col width="75%">
                                </colgroup>
                                <tr>
                                    <th>레벨이름</th><td>{{ modifyItem.level }}</td>
                                </tr>
                                <tr>
                                    <th>등업기준</th>
                                    <td>
                                        <input type="number" name="" v-model="modifyItem.like_cnt" class="wx40 mr10">공감
                                        <input type="number" name="" v-model="modifyItem.reply_cnt" class="wx40 mr10 ml10">댓글
                                        <input type="number" name="" v-model="modifyItem.post_cnt" class="wx40 mr10 ml10">관찰수
                                        <input type="number" name="" v-model="modifyItem.pic_cnt" class="wx40 mr10 ml10">사진수
                                        <span class="bold ml10">만족 시 자동등업</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>등급순서</th><td><input type="number" name="" v-model="modifyItem.order"></td>
                                </tr>
                            </table>
                            <div class="tac">
                                <button class="bg grey button mid mt20" type="button" name="button" @click="onClickModifyDoneButton">수정하기</button>
                            </div>
                        </template>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'

export default {
    name: 'InfoGradePage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        return {
            pageTitle: '회원등급 관리',
            pageSubTitle: '시민과학자 등급관리',
            gridShow: true,
            pageSettings: { pageSize: 10, pageCount: 5 },
            listData: [],
            registerItem: {
                level: '',
                likeCnt: '',
                postCnt: '',
                replyCnt: '',
                picCnt: '',
                order: '',
            },
            modifyOriginItem: {},
            modifyItem: {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
        this.reqGradeList()
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async reqGradeList() {
            this.selectedItem = {}
            this.modifyItem = {}
            const _this = this
            const response = await this.requestGet(Constants.API.gradeList)
            if (response) {
                this.listData.length = 0
                this.listData.push(..._.orderBy(_.forEach(response.level_list || [], species => {
                    if (species.reg_date) {
                        species.reg_date_ymd = _this.dateTimeSetFormat(species.reg_date, 'YYYY-MM-DD')
                        species.reg_date_full = _this.dateTimeSetFormat(species.reg_date)
                    } else {
                        species.reg_date_ymd = '-'
                        species.reg_date_full = '-'
                    }
                    if (species.mod_date) {
                        species.mod_date_ymd = _this.dateTimeSetFormat(species.mod_date, 'YYYY-MM-DD')
                        species.mod_date_full = _this.dateTimeSetFormat(species.mod_date)
                    } else {
                        species.mod_date_ymd = '-'
                        species.mod_date_full = '-'
                    }
                    if (species.show_flag) {
                        species.show_flag_name = species.show_flag === '1' ? '노출' : species.show_flag === '0' ? '감추기' : '-'
                    } else {
                        species.show_flag_name = '-'
                    }
                }), 'order'))
                this.gridShow = false
                setTimeout(function () {
                    _this.gridShow = true
                }, 50)
            }
        },

        onClickModifyStartButton(data) {
            // this.modifyOriginItem = _.cloneDeep(data)
            this.modifyOriginItem.level = data.level || ''
            this.modifyOriginItem.like_cnt = data.like_cnt || ''
            this.modifyOriginItem.order = data.order || ''
            this.modifyOriginItem.pic_cnt = data.pic_cnt || ''
            this.modifyOriginItem.post_cnt = data.post_cnt || ''
            this.modifyOriginItem.reply_cnt = data.reply_cnt || ''
            // this.modifyItem = _.cloneDeep(data)
            this.modifyItem.level = data.level || ''
            this.modifyItem.like_cnt = data.like_cnt || ''
            this.modifyItem.order = data.order || ''
            this.modifyItem.pic_cnt = data.pic_cnt || ''
            this.modifyItem.post_cnt = data.post_cnt || ''
            this.modifyItem.reply_cnt = data.reply_cnt || ''
        },
        async onClickRegisterButton() {
            if (!this.registerItem.level) {
                this.showAlert('알림', '레벨이름을 입력해주세요.')
                return
            }
            if (this.registerItem.likeCnt === undefined || this.registerItem.likeCnt === '') {
                this.showAlert('알림', '공감 기준을 입력해주세요.')
                return
            }
            if (this.registerItem.replyCnt === undefined || this.registerItem.replyCnt === '') {
                this.showAlert('알림', '댓글 기준을 입력해주세요.')
                return
            }
            if (this.registerItem.postCnt === undefined || this.registerItem.postCnt === '') {
                this.showAlert('알림', '관찰수 기준을 입력해주세요.')
                return
            }
            if (this.registerItem.picCnt === undefined || this.registerItem.picCnt === '') {
                this.showAlert('알림', '사진수 기준을 입력해주세요.')
                return
            }
            if (this.registerItem.order === undefined || this.registerItem.order === '') {
                this.showAlert('알림', '등급순서를 입력해주세요.')
                return
            }
            await this.reqGradeRegister()
        },
        async reqGradeRegister() {
            if (!this.managerEmail) return
            let params = {
                email: this.managerEmail,
                level: this.registerItem.level,
                like_cnt: this.registerItem.likeCnt,
                reply_cnt: this.registerItem.replyCnt,
                post_cnt: this.registerItem.postCnt,
                pic_cnt: this.registerItem.picCnt,
                order: this.registerItem.order,
            }
            const response = await this.requestPost(Constants.API.gradeRegister, params)
            if (response) {
                this.showAlert('알림', '생물유형 등록이 완료되었습니다.')
                this.registerItem.level = ''
                this.registerItem.likeCnt = ''
                this.registerItem.replyCnt = ''
                this.registerItem.postCnt = ''
                this.registerItem.picCnt = ''
                this.registerItem.order = ''
                await this.reqGradeList()
            }
        },
        async onClickModifyDoneButton() {
            if (!this.modifyOriginItem.level) return
            if (this.modifyItem.like_cnt === undefined || this.modifyItem.like_cnt === '') {
                this.showAlert('알림', '공감 기준을 입력해주세요.')
                return
            }
            if (this.modifyItem.reply_cnt === undefined || this.modifyItem.reply_cnt === '') {
                this.showAlert('알림', '댓글 기준을 입력해주세요.')
                return
            }
            if (this.modifyItem.post_cnt === undefined || this.modifyItem.post_cnt === '') {
                this.showAlert('알림', '관찰수 기준을 입력해주세요.')
                return
            }
            if (this.modifyItem.pic_cnt === undefined || this.modifyItem.pic_cnt === '') {
                this.showAlert('알림', '사진수 기준을 입력해주세요.')
                return
            }
            if (this.modifyItem.order === undefined || this.modifyItem.order === '') {
                this.showAlert('알림', '등급순서를 입력해주세요.')
                return
            }
            await this.reqGradeModify()
        },
        async reqGradeModify() {
            if (!this.managerEmail) return
            let params = {
                email: this.managerEmail,
                level: this.modifyOriginItem.level,
                like_cnt: this.modifyItem.like_cnt,
                reply_cnt: this.modifyItem.reply_cnt,
                post_cnt: this.modifyItem.post_cnt,
                pic_cnt: this.modifyItem.pic_cnt,
                order: this.modifyItem.order,
            }
            const response = await this.requestPost(Constants.API.gradeModify, params)
            if (response) {
                this.showAlert('알림', '생물유형 수정이 완료되었습니다.')
                await this.reqGradeList()
            }
        },
    },
}
</script>