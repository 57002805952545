import Axios from '@/common/axios'
import Constants from '@/common/constants'
import { mapMutations } from 'vuex'

export const NetworkFunctions = {
    computed: {
    },
    methods: {
        ...mapMutations(['addPreloaderShowCount', 'subPreloaderShowCount']),
        async requestPost(api, params, directReturn = false, errorMessage = null, withPreloader = true) {
            try {
                if (withPreloader) this.addPreloaderShowCount()
                let response = await Axios.post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${api}`, params)
                const {
                    data: { resultCode, resultMessage }
                } = response
                if (directReturn && response) {
                    console.log(response)
                    console.log(response.data)
                    return response.data || {}
                }
                if (resultCode !== Constants.API_RESPONSE_CODE.success) {
                    this.errorHandler(api, resultCode, errorMessage || resultMessage)
                    return
                }
                return response.data || {}
            } catch (e) {
                if (this.showAlert) this.showAlert('알림', `${e}`)
                else alert(e)
            } finally {
                if (withPreloader) this.subPreloaderShowCount()
            }
        },
        async requestGet(api, params, directReturn = false, errorMessage = null, withPreloader = true) {
            try {
                if (withPreloader) this.addPreloaderShowCount()
                let response = await Axios.get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${api}`, params)
                const {
                    data: { resultCode, resultMessage }
                } = response
                if (directReturn && response) return response.data || {}
                if (resultCode !== Constants.API_RESPONSE_CODE.success) {
                    this.errorHandler(api, resultCode, errorMessage || resultMessage)
                    return
                }
                return response.data || {}
            } catch (e) {
                if (this.showAlert) this.showAlert('알림', `${e}`)
                else alert(e)
            } finally {
                if (withPreloader) this.subPreloaderShowCount()
            }
        },
        async requestFormData(api, params, directReturn = false, errorMessage = null, withPreloader = true) {
            try {
                if (withPreloader) this.addPreloaderShowCount()
                let response = await Axios.post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${api}`, params, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                const {
                    data: { resultCode, resultMessage }
                } = response
                if (directReturn && response) return response.data || {}
                if (resultCode !== Constants.API_RESPONSE_CODE.success) {
                    this.errorHandler(api, resultCode, errorMessage || resultMessage)
                    return
                }
                return response.data || {}
            } catch (e) {
                if (this.showAlert) this.showAlert('알림', `${e}`)
                else alert(e)
            } finally {
                if (withPreloader) this.subPreloaderShowCount()
            }
        },
        errorHandler(api, code, message) {
            throw new Error(`${message}|${code}`)
        },
        async requestAppPost(api, params, directReturn = false, errorMessage = null, withPreloader = true) {
            try {
                if (withPreloader) this.addPreloaderShowCount()
                let response = await Axios.post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PR_PREFIX}${api}`, params)
                const {
                    data: { resultCode, resultMessage }
                } = response
                if (directReturn && response) {
                    console.log(response)
                    console.log(response.data)
                    return response.data || {}
                }
                if (resultCode !== Constants.API_RESPONSE_CODE.success) {
                    this.errorHandler(api, resultCode, errorMessage || resultMessage)
                    return
                }
                return response.data || {}
            } catch (e) {
                if (this.showAlert) this.showAlert('알림', `${e}`)
                else alert(e)
            } finally {
                if (withPreloader) this.subPreloaderShowCount()
            }
        },
    },
}