<template>
    <router-view />
    <modals-container></modals-container>
</template>

<script>
import { Page } from '@syncfusion/ej2-vue-grids'
import { CheckBoxSelection } from '@syncfusion/ej2-vue-dropdowns'
import { Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table, FileManager } from '@syncfusion/ej2-vue-richtexteditor'


export default {
    name: 'App',
    mounted() {
        // if (!window.kakao || window.kakao.maps) {
        //     const script = document.createElement("script");
        //     /* global kakao */
        //     script.onload = () => kakao.maps.load(this.initMap);
        //     script.src = "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=7fef8bb7edf8f62b18a1de3c857ece39";
        //     document.head.appendChild(script);
        // }
    },
    provide: {
        grid: [Page],
        multiselect: [CheckBoxSelection],
        richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar, Table, FileManager]
    }
}
</script>

<style>
/*@import '../node_modules/@syncfusion/ej2-base/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';*/
/*@import '../node_modules/@syncfusion/ej2-vue-grids/styles/material.css';*/
@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap5.css';
@import '../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/bootstrap5.css';
</style>
