<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'약관 및 정책 관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageSubTitle }}</h2>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="divide2" style="display: block;">
                                <label v-for="(item, i) in listData" class="radiolabel ml20" :key="`terms-${i}`" :for="`terms-${i}`">
                                    {{ item.title }}
                                    <input :id="`terms-${i}`" type="radio" name="radio01" :value="item.title" v-model="selectedTitle">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="mt35">
                    <ejs-richtexteditor
                        ref="rteObj"
                        height="500"
                        :enabled="!!modifyItem.title"
                        :value="modifyItem.contents"
                        v-model:value="modifyItem.contents"
                        :toolbarSettings="toolbarSettings"
                        :actionBegin="handleFullScreen"
                        :actionComplete="actionCompleteHandler"
                        :showCharCount="true"
                        :quickToolbarSettings="quickToolbarSettings"></ejs-richtexteditor>
                    <div class="tac">
                        <button v-if="selectedItem.title" class="bg blue button mid mt20" type="button" name="button" @click="onClickModifyButton">수정하기</button>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'

export default {
    name: 'TermsPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
    },
    data() {
        return {
            pageTitle: '약관 및 정책',
            pageSubTitle: '약관 및 정책 관리',
            listData: [],
            selectedTitle: '',
            selectedItem: {contents:''},
            modifyItem: {contents:''},
            quickToolbarSettings: {
                table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
            },
        }
    },
    computed: {
        ...mapGetters(['managerEmail']),
        toolbarSettings () {
            if (!this.modifyItem.title) return {
                type: 'Expand',
                items: [],
            }
            return {
                type: 'Expand',
                items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
                    'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
                    'LowerCase', 'UpperCase', '|',
                    'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
                    'Outdent', 'Indent', '|',
                    'CreateLink', '|', 'ClearFormat', 'Print',
                    'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'],
            }
        },
    },
    watch: {
        selectedTitle(val) {
            this.selectedItem = _.find(this.listData || [], item => {
                return item.title === val
            }) || {contents:''}
            this.modifyItem = _.cloneDeep(this.selectedItem)
        }
    },
    mounted() {
        this.reqTermsList()
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async reqTermsList() {
            this.selectedTitle = ''
            this.selectedItem = {}
            const _this = this
            const response = await this.requestGet(Constants.API.termsList)
            if (response) {
                this.listData.length = 0
                this.listData.push(..._.orderBy(_.forEach(response.terms_list || [], species => {
                    if (species.reg_date) {
                        species.reg_date_ymd = _this.dateTimeSetFormat(species.reg_date, 'YYYY-MM-DD')
                        species.reg_date_full = _this.dateTimeSetFormat(species.reg_date)
                    } else {
                        species.reg_date_ymd = '-'
                        species.reg_date_full = '-'
                    }
                    if (species.mod_date) {
                        species.mod_date_ymd = _this.dateTimeSetFormat(species.mod_date, 'YYYY-MM-DD')
                        species.mod_date_full = _this.dateTimeSetFormat(species.mod_date)
                    } else {
                        species.mod_date_ymd = '-'
                        species.mod_date_full = '-'
                    }
                }), 'order'))
            }
        },
        async onClickRegisterButton() {
            await this.reqTermsRegister()
        },
        async reqTermsRegister() {
            if (!this.managerEmail) return
            let params = {
                email: this.managerEmail,
                level: this.registerItem.level,
                like_cnt: this.registerItem.likeCnt,
                reply_cnt: this.registerItem.replyCnt,
                post_cnt: this.registerItem.postCnt,
                pic_cnt: this.registerItem.picCnt,
                order: this.registerItem.order,
            }
            const response = await this.requestPost(Constants.API.gradeRegister, params)
            if (response) {
                this.showAlert('알림', '약관 및 정책 등록이 완료되었습니다.')
                await this.reqTermsList()
            }
        },
        async onClickModifyButton() {
            if (!this.modifyItem.title === this.selectedItem.title) return
            if (!this.modifyItem.title) {
                this.showAlert('알림', '제목을 입력해주세요.')
                return
            }
            if (!this.modifyItem.contents) {
                this.showAlert('알림', '내용을 입력해주세요.')
                return
            }
            await this.reqTermsModify()
        },
        async reqTermsModify() {
            if (!this.managerEmail) return
            let params = {
                email: this.managerEmail,
                terms_id: this.selectedItem.terms_id,
            }
            if (this.modifyItem.title !== this.selectedItem.title) params.title = this.modifyItem.title
            if (this.modifyItem.contents !== this.selectedItem.contents) params.contents = this.modifyItem.contents
            console.log(params)
            if (this.getObjectLength(params) < 3) return
            const response = await this.requestPost(Constants.API.termsModify, params)
            if (response) {
                this.showAlert('알림', '약관 및 정책 수정이 완료되었습니다.')
                await this.reqTermsList()
            }
        },
        async onClickDeleteButton() {
            if (!this.selectedItem.title) return
            await this.reqTermsDelete()
        },
        async reqTermsDelete() {
            if (!this.managerEmail) return
            let params = {
                email: this.managerEmail,
                level: this.modifyOriginItem.level,
                like_cnt: this.modifyItem.like_cnt,
                reply_cnt: this.modifyItem.reply_cnt,
                post_cnt: this.modifyItem.post_cnt,
                pic_cnt: this.modifyItem.pic_cnt,
                order: this.modifyItem.order,
            }
            const response = await this.requestPost(Constants.API.termsDelete, params)
            if (response) {
                this.showAlert('알림', '약관 및 정책 삭제가 완료되었습니다.')
                await this.reqTermsList()
            }
        },
    },
}
</script>