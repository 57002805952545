<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'회원관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }}</h2>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="">
                                이메일<input type="text" name="" data-pcwid="200" class="wx200 mr40" v-model="search.email" @keyup.enter="reloadGridData">
                                이름<input type="text" name="" data-pcwid="200" class="wx1200 mr40" v-model="search.name" @keyup.enter="reloadGridData">
                                레벨
                                <select class="wx200 mr40" name="" v-model="search.level" @change="reloadGridData">
                                    <optgroup label="*** 레벨을 선택해주세요 ***">
                                        <option value="">전체</option>
                                        <option v-for="(item, i) in levelList" :value="item.level" :key="`level-${i}`">{{ item.level }}</option>
                                    </optgroup>
                                </select>
                                회원상태
                                <select class="wx200 mr40" name="" v-model="search.status" @change="reloadGridData">
                                    <optgroup label="*** 상태를 선택해주세요 ***">
                                        <option value="">전체</option>
                                        <option value="1">활성화</option>
                                        <option value="0">비활성화</option>
                                    </optgroup>
                                </select>
                                <button class="bg blue button mid mr200" type="button" name="button" @click="reloadGridData">검색</button>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="divide">
                    <div class="cont_left">
                        <div class="table_header">
                            <div class="dpinline fL">
                                <div class="f14 pt5" style="padding-top: 7px;">총 {{ listSize || '0' }}건</div>
                            </div>
                        </div>
                        <div class="table_wrap">
                            <ejs-grid
                                locale="ko-KR"
                                v-if="gridShow"
                                ref="grid"
                                :dataSource="data"
                                :allowPaging="true"
                                :pageSettings="pageSettings"
                                :rowSelected="onSelectItem"
                                :rowDeselected="onDeselectItem">
                                <e-columns>
                                    <e-column textAlign="Center" width=71 :template="'thumbImage'"></e-column>
                                    <e-column field="nickname" headerText="이름" textAlign="Center" width=97></e-column>
                                    <e-column field="email" headerText="이메일" textAlign="Center" width=284></e-column>
                                    <e-column field="grade" headerText="회원등급" textAlign="Center" width=144></e-column>
                                    <e-column field="status_name" headerText="회원상태" textAlign="Center" width=120></e-column>
                                    <e-column field="level" headerText="레벨" textAlign="Center" width=74></e-column>
                                    <e-column field="reg_date_ymd" headerText="가입일자" textAlign="Center" width=154></e-column>
                                </e-columns>
                                <template v-slot:thumbImage="{data}">
                                    <span style="display: inline-block; width:24px; height:24px; border-radius:25px; background: #bababa; overflow: hidden;">
                                        <img style="min-width: 100%; min-height: 100%; background-position: center center; background-repeat: no-repeat;" class="circle pointer" v-if="data.pic_url" :src="data.pic_url" :alt="data.pic_url" @click.prevent="showImageViewer(data.pic_url)">
                                    </span>
                                </template>
                            </ejs-grid>
                        </div>
                    </div>
                    <div class="cont_right table_wrap">
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="30%">
                                <col width="70%">
                            </colgroup>
                            <tr>
                                <th>프로필 사진</th>
                                <td>
                                    <span style="display: inline-block; width:48px; height:48px; border-radius:25px; background: #bababa;" v-if="selectedItem.pic_url">
                                        <img style="min-width: 100%; min-height: 100%; background-position: center center; background-repeat: no-repeat;" class="circle pointer" :src="selectedItem.pic_url" :alt="selectedItem.pic_url" @click.prevent="showImageViewer(selectedItem.pic_url)">
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>{{ selectedItem.nickname || '-' }}</td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td>{{ selectedItem.email || '-' }}</td>
                            </tr>
                            <tr>
                                <th>가입일자</th>
                                <td>{{ selectedItem.reg_date_full || '-' }}</td>
                            </tr>
                            <tr>
                                <th>회원등급</th>
                                <td class="divide5">
                                    {{ selectedItem.grade || '-' }}
                                    <button v-if="selectedItem.grade === '시민과학자'" @click="onClickUserGradeUp" class="bg blue button mid fR" type="button" name="button">생태전문가로 등업하기</button>
                                </td>
                            </tr>
                            <tr>
                                <th>회원레벨</th>
                                <td>{{ selectedItem.level || '-' }}</td>
                            </tr>
                            <tr>
                                <th>회원상태</th>
                                <td class="divide5">
                                    {{ selectedItem.status_name || '-' }}
                                    <button v-if="selectedItem.status === '0'" @click="onClickUserEnable" class="bg blue button mid fR" type="button" name="button">회원 활성화 하기</button>
                                    <button v-if="selectedItem.status === '1'" @click="onClickUserDisable" class="bg grey button mid fR" type="button" name="button">회원 비활성화 하기</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'

export default {
    name: 'MemberUserPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        const _this = this
        class PagingListManager extends DataManager {
        }
        class PagingListAdaptor extends UrlAdaptor {
            beforeSend(dataManager, request, ajaxSettings) {
                request.withCredentials = false
                // _this.addPreloaderShowCount()
                try {
                    const page = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.currentPage
                    const row = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.pageSize
                    let param = {
                        page_num: page || 1,
                        row_num: row || 10,
                    }
                    if (_this.search.email) param.email = _this.search.email
                    if (_this.search.name) param.name = _this.search.name
                    if (_this.search.level) param.level = _this.search.level
                    if (_this.search.status) param.status = _this.search.status
                    const data = JSON.stringify(param)
                    ajaxSettings.data = data
                    ajaxSettings.options.data = data
                    // const sessionKey = _this.sessionKey
                    // request.setRequestHeader('session_id', sessionKey)
                } catch (e) {
                    // _this.subPreloaderShowCount()
                }
            }
            processResponse() {
                // _this.subPreloaderShowCount()
                let original = super.processResponse.apply(this, arguments)
                const list = []
                let count = 0
                if (original.resultCode === Constants.API_RESPONSE_CODE.invalidSessionId) {
                    _this.setManagerEmail(undefined)
                    _this.moveTo('Login')
                } else if (original.resultCode !== 200) {
                    _this.showAlert('Alert', original.resultMessage)
                } else {
                    count = Number(original.total_cnt || '0')
                    _.forEach(original.member_list || [], function (item) {
                        item.status_name = item.status === '1' ? '활성화' : item.status === '0' ? '비활성화' : '-'
                        if (item.reg_date) {
                            item.reg_date_ymd = _this.dateTimeSetFormat(item.reg_date, 'YYYY-MM-DD')
                            item.reg_date_full = _this.dateTimeSetFormat(item.reg_date)
                        } else {
                            item.reg_date_ymd = '-'
                            item.reg_date_full = '-'
                        }
                        list.push(item)
                    })
                }
                _this.listSize = count
                return { result: list, count: count }
            }
        }
        return {
            pageTitle: '사용자 회원관리',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                email: '',
                name: '',
                level: '',
                status: '',
            },
            levelList: [],
            pageSettings: { pageSize: 10, pageCount: 5 },
            data: new PagingListManager({
                url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.memberList}`,
                adaptor: new PagingListAdaptor(),
                crossDomain: true
            }),
            selectedItem: {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
        this.reqGradeList()
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async reqGradeList() {
            this.selectedItem = {}
            this.modifyItem = {}
            const response = await this.requestGet(Constants.API.gradeList)
            if (response) {
                this.levelList.length = 0
                this.levelList.push(..._.orderBy(response.level_list || [], 'order'))
            }
        },
        onSelectItem(user) {
            this.selectedItem = user.data
        },
        onDeselectItem() {
            this.selectedItem = {}
        },
        reloadGridData() {
            const _this = this
            this.onDeselectItem()
            this.gridShow = false
            setTimeout(function () {
                _this.gridShow = true
            }, 50)
        },
        async onClickUserGradeUp() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.nickname || this.selectedItem.email}] 사용자롤 생태전문가로 변경하시겠습니까?`, '확인', async () => {
                await this.reqUserGradeUp()
            })
        },
        async reqUserGradeUp() {
            let params = {
                email: this.managerEmail,
                member_email: this.selectedItem.email,
            }
            const response = await this.requestPost(Constants.API.memberGrade, params)
            if (response) {
                this.showAlert('알림', '사용자 등급 변경이 완료되었습니다.')
                this.$refs.grid.refresh()
            }
        },
        async onClickUserEnable() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.nickname || this.selectedItem.email}] 사용자롤 활성화 상태로 변경하시겠습니까?`, '확인', async () => {
                await this.reqUserEnable()
            })
        },
        async reqUserEnable() {
            let params = {
                email: this.managerEmail,
                member_email: this.selectedItem.email,
                status: '1',
            }
            const response = await this.requestPost(Constants.API.memberStatus, params)
            if (response) {
                this.showAlert('알림', '사용자 활성화가 완료되었습니다.')
                this.$refs.grid.refresh()
            }
        },
        async onClickUserDisable() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.nickname || this.selectedItem.email}] 사용자롤 비활성화 상태로 변경하시겠습니까?`, '확인', async () => {
                await this.reqUserDisable()
            })
        },
        async reqUserDisable() {
            let params = {
                email: this.managerEmail,
                member_email: this.selectedItem.email,
                status: '0',
            }
            const response = await this.requestPost(Constants.API.memberStatus, params)
            if (response) {
                this.showAlert('알림', '사용자 비활성화가 완료되었습니다.')
                this.$refs.grid.refresh()
            }
        },
    },
}
</script>