const account = {
    state: {
        managerEmail: '',
    },
    getters: {
        managerEmail: state => state.managerEmail,
    },
    mutations: {
        setManagerEmail: (state, key) => {
            state.managerEmail = key || ''
        },
    },
}

export default account