<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info" class="pop_wrap">
            <div class="pop_inner04">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">문의사항 답변하기</p>
                <div class="cont_body mt15">
                    <div class="table_wrap">
                        <p class="f20 bold mb10">{{ item.title }}</p>
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="20%">
                                <col width="80%">
                            </colgroup>
                            <tr>
                                <th>작성자</th><td>{{ item.reg_name || item.reg_email }}</td>
                            </tr>
                            <tr>
                                <th>등록일</th><td>{{ item.reg_date_full }}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div class="inquiry_area" v-html="item.question_html"></div>
                                </td>
                            </tr>
                        </table>
                        <p class="f20 bold mb10 mt20">답변내용</p>
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="20%">
                                <col width="80%">
                            </colgroup>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <textarea name="name" rows="4" cols="65" v-model="answer"></textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="tac mb20">
                        <button class="bg grey fc white button mid mt30" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg blue button mid mt30 ml5" type="button" name="button" @click="onClickModifyButton">답변하기</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'InquiryAnswer',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            item: {},
            doneRefresh: () => {},
            answer: '',
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            if (!this.managerEmail) {
                this.showAlert('알림', '로그인 계정 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            if (params && params._value) {
                this.item = params._value.item || {}
                this.doneRefresh = params._value.refresh || (() => {})
            }
            if (!this.item.qna_id) {
                this.showAlert('알림', '문의 정보 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            this.title = this.item.title
            this.contents = this.item.contents
            this.start_date = this.item.start_date_ymd
            this.end_date = this.item.end_date_ymd
        },
        async onClickModifyButton() {
            if (!this.answer) {
                this.showAlert('알림', '답변을 입력해주세요.')
                return
            }
            await this.requestAnswer()
        },
        async requestAnswer() {
            let params = {
                email: this.managerEmail,
                qna_id: this.item.qna_id,
                contents: this.answer,
            }
            const response = await this.requestPost(Constants.API.qnaAnswer, params)
            if (response) {
                this.showAlert('알림', '답변 등록이 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
                this.doneRefresh()
            }
        },
    },
}
</script>