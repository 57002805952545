<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'관찰정보관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }}</h2>
                    </div>
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="divide2">
                    <!-- search box -->
                    <div class="cont_left">
                        <div class="searchbox">
                            <div class="searchcon">
                                <div class="divide3">
                                    <div class="">
                                        생물유형
                                    </div>
                                    <div class="wx200 ml10 mr40">
                                        <ejs-multiselect
                                            :dataSource="speciesList"
                                            placeholder="생물유형을 선택해주세요"
                                            :fields="{ text: 'species_type', value: 'species_type' }"
                                            mode="CheckBox"
                                            :allowFiltering="false"
                                            :showClearButton="true"
                                            :showDropDownIcon="true"
                                            :enableSelectionOrder="false"
                                            v-model:value="search.species_type"
                                            :changeOnBlur="false"
                                        ></ejs-multiselect>
                                    </div>
                                    <div class="">
                                        검색기간
                                    </div>
                                    <div class="wx230 ml10 mr10">
                                        <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.start_date"></ejs-datepicker>
                                    </div>
                                    <div class="wx230 ml10 mr40">
                                        <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.end_date"></ejs-datepicker>
                                    </div>
                                    <div class="">
                                        표시상태
                                        <select class="wx150 mr40" name="" v-model="search.status" @change="reloadGridData">
                                            <optgroup label="*** 상태를 선택해주세요 ***">
                                                <option value="">전체</option>
                                                <option value="1">활성화</option>
                                                <option value="0">비활성화</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                    <div class="">
                                        작성자<input type="text" name="" class="wx150 mr40" v-model="search.name" @keyup.enter="reloadGridData">
                                    </div>
                                    <div class="">
                                        생물이름<input type="text" name="" class="wx150 mr40" v-model="search.contents_name" @keyup.enter="reloadGridData">
                                    </div>
                                    <div class="">
                                        <label class="checklabel wx200 mt20 mb10">일치하는 생물 이름만 검색
                                            <input type="checkbox" v-model="search.match_flag" @change="reloadGridData">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="checklabel wx150 mt20 mb10">신고된 게시물만 검색
                                            <input type="checkbox" v-model="search.violation_flag" @change="reloadGridData">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="ml230">
                                        <button class="bg blue button mid" type="button" name="button" @click="reloadGridData">검색</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /search box -->
                        <div class="mt35">
                            <div class="table_header">
                                <div class="dpinline fL">
                                    <div class="f14 pt5" style="padding-top: 7px;">총 {{ listSize || '0' }}건</div>
                                </div>
                            </div>
                            <div class="table_wrap">
                                <ejs-grid
                                    locale="ko-KR"
                                    v-if="gridShow"
                                    ref="grid"
                                    :dataSource="data"
                                    :allowPaging="true"
                                    :pageSettings="pageSettings"
                                    :rowSelected="onSelectItem"
                                    :rowDeselected="onDeselectItem">
                                    <e-columns>
                                        <e-column headerText="관찰사진" textAlign="Center" width=70 :template="'thumbImage'"></e-column>
                                        <e-column field="contents_nm" headerText="생물이름" textAlign="Center" width=70></e-column>
                                        <e-column field="species_type" headerText="생물유형" textAlign="Center" width=70></e-column>
                                        <e-column field="reg_name" headerText="작성자" textAlign="Center" width=57></e-column>
                                        <e-column field="reg_addr" headerText="관찰위치" textAlign="Center" width=158></e-column>
                                        <e-column field="main_text" headerText="관찰내용" textAlign="Center" width=226></e-column>
                                        <e-column field="reg_date_ymd" headerText="관찰시간" textAlign="Center" width=90></e-column>
                                        <e-column field="status_name" headerText="표시상태" textAlign="Center" width=70></e-column>
                                        <e-column field="like_cnt" headerText="공감수" textAlign="Center" width=57></e-column>
                                        <e-column field="reply_cnt" headerText="댓글수" textAlign="Center" width=70></e-column>
                                    </e-columns>
                                    <template v-slot:thumbImage="{data}">
                                        <span style="display: inline-block; width:44px; height:44px; background: #bababa; overflow: hidden;">
                                            <img class="pointer" style="min-width: 100%; min-height: 100%; background-position: center center; background-repeat: no-repeat;" v-if="data.picurl_list.length > 0" :src="data.picurl_list[0].picurl" :alt="data.picurl_list[0].picurl" @click.prevent="showImageViewer(data.picurl_list[0].picurl)">
                                        </span>
                                    </template>
                                </ejs-grid>
                            </div>
                        </div>
                    </div>
                    <div class="cont_right table_wrap">
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="30%">
                                <col width="70%">
                            </colgroup>
                            <tr>
                                <th>관찰사진</th>
                                <td>
                                    <span class="mr5" v-for="(picurl, i) in selectedItem.picurl_list" style="display: inline-block; height:60px; background: #bababa;" :key="`picture${i}`">
                                        <img class="pointer" style="height:60px;" v-if="picurl.picurl" :src="picurl.picurl" :alt="picurl.picurl" @click.prevent="showImageViewer(picurl.picurl)">
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>생물이름</th>
                                <td>
                                    {{ selectedItem.contents_nm || '-' }}
                                    <span v-if="selectedItem.contents_url" class="ml20 fc white bg grey">국민생활자원관</span>
                                </td>
                            </tr>
                            <tr>
                                <th>생물유형</th>
                                <td>{{ selectedItem.species_type || '-' }}</td>
                            </tr>
                            <tr>
                                <th>작성자</th>
                                <td>{{ selectedItem.reg_name || '-' }}</td>
                            </tr>
                            <tr>
                                <th>관찰위치</th>
                                <td>{{ selectedItem.reg_addr || '-' }}</td>
                            </tr>
                            <tr>
                                <th>관찰시간</th>
                                <td>{{ selectedItem.reg_date_full || '-' }}</td>
                            </tr>
                            <tr>
                                <th>관찰날씨</th>
                                <td>{{ selectedItem.weather || '-' }}</td>
                            </tr>
                            <tr>
                                <th>공감수</th>
                                <td>{{ selectedItem.like_cnt === undefined ? '-' : selectedItem.like_cnt }}</td>
                            </tr>
                            <tr>
                                <th>댓글수</th>
                                <td>{{ selectedItem.reply_cnt === undefined ? '-' : selectedItem.reply_cnt }}</td>
                            </tr>
                        </table>
                        <div class="sub_table_header">
                            <div class="dpinline fL">
                                <div class="f14 pt5" style="padding-top: 7px;">이름제안 & 댓글</div>
                            </div>
                        </div>
                        <ejs-grid
                            locale="ko-KR"
                            v-if="selectedItem.sub_list"
                            ref="subGrid"
                            cssClass="table_list"
                            :dataSource="selectedItem.sub_list"
                            :allowPaging="true"
                            :pageSettings="subPageSettings">
                            <e-columns>
                                <e-column field="type" headerText="구분" textAlign="Center" width=58></e-column>
                                <e-column field="reg_name" headerText="작성자" textAlign="Center" width=76></e-column>
                                <e-column field="content" headerText="내용" textAlign="Center" width=94></e-column>
                                <e-column field="reg_date" headerText="작성시간" textAlign="Center" width=201></e-column>
                                <e-column field="etc" headerText="비고" textAlign="Center" width=176></e-column>
                            </e-columns>
                        </ejs-grid>
                        <div class="tac mt20">
                            <a v-if="selectedItem.contents_id" href="#" class="btn_open bg orange button mid" @click.prevent="onClickHideButton">관찰기록 감추기</a>
                        </div>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import RecordHide from './modals/RecordHide'

export default {
    name: 'ObserveRecordPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        const _this = this
        class PagingListManager extends DataManager {
        }
        class PagingListAdaptor extends UrlAdaptor {
            beforeSend(dataManager, request, ajaxSettings) {
                request.withCredentials = false
                // _this.addPreloaderShowCount()
                try {
                    const page = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.currentPage
                    const row = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.pageSize
                    let param = {
                        page_num: page || 1,
                        row_num: row || 5,
                    }
                    if (_this.search.species_type.length > 0) param.species_type = _this.search.species_type.join('|')
                    if (_this.search.start_date) {
                        let start = moment(_this.search.start_date).format('YYYYMMDD')
                        if (start) param.start_date = start
                    }
                    if (_this.search.end_date) {
                        let end = moment(_this.search.end_date).format('YYYYMMDD')
                        if (end) param.end_date = end
                    }
                    if (_this.search.status) param.status = _this.search.status
                    if (_this.search.name) param.name = _this.search.name
                    if (_this.search.contents_name) param.contents_name = _this.search.contents_name
                    if (_this.search.match_flag) param.match_flag = '1'
                    if (_this.search.violation_flag) param.violation_flag = '1'
                    const data = JSON.stringify(param)
                    ajaxSettings.data = data
                    ajaxSettings.options.data = data
                    // const sessionKey = _this.sessionKey
                    // request.setRequestHeader('session_id', sessionKey)
                } catch (e) {
                    // _this.subPreloaderShowCount()
                }
            }
            processResponse() {
                // _this.subPreloaderShowCount()
                let original = super.processResponse.apply(this, arguments)
                const list = []
                let count = 0
                if (original.resultCode === Constants.API_RESPONSE_CODE.invalidSessionId) {
                    _this.setManagerEmail(undefined)
                    _this.moveTo('Login')
                } else if (original.resultCode !== 200) {
                    _this.showAlert('Alert', original.resultMessage)
                } else {
                    count = Number(original.total_cnt || '0')
                    _.forEach(original.post_list || [], function (item) {
                        item.status_name = item.status === '1' ? '활성화' : item.status === '0' ? '비활성화' : '-'
                        item.reg_name = item.reg_nickname || item.reg_email
                        if (item.reg_date) {
                            item.reg_date_ymd = _this.dateTimeSetFormat(item.reg_date, 'YYYY-MM-DD')
                            item.reg_date_full = _this.dateTimeSetFormat(item.reg_date)
                        } else {
                            item.reg_date_ymd = '-'
                            item.reg_date_full = '-'
                        }
                        item.sub_list = []
                        _.forEach(item.reply_list || [], reply => {
                            const sub = {}
                            sub.type = '댓글'
                            sub.reg_name = reply.reg_nickname || reply.reg_email
                            sub.content = reply.reply_text
                            sub.reg_date = reply.reg_date ? _this.dateTimeSetFormat(reply.reg_date) : '-'
                            sub.etc = ''
                            item.sub_list.push(sub)
                        })
                        _.forEach(item.reco_list || [], reco => {
                            const sub = {}
                            sub.type = '제안'
                            sub.reg_name = reco.reg_nickname || reco.reg_email
                            sub.content = reco.contents_nm
                            sub.reg_date = reco.reg_date ? _this.dateTimeSetFormat(reco.reg_date) : '-'
                            sub.etc = ''
                            item.sub_list.push(sub)
                        })
                        list.push(item)
                    })
                }
                _this.listSize = count
                return { result: list, count: count }
            }
        }
        return {
            pageTitle: '관찰기록 관리',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                species_type: [],
                start_date: '',
                end_date: '',
                status: '',
                name: '',
                contents_name: '',
                match_flag: false,
                violation_flag: false,
            },
            pageSettings: { pageSize: 5, pageCount: 5 },
            subPageSettings: { pageSize: 3, pageCount: 5 },
            data: new PagingListManager({
                url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.postList}`,
                adaptor: new PagingListAdaptor(),
                crossDomain: true
            }),
            selectedItem: {},
            modifyMode: false,
            modifyItem: {},
            speciesList: [],
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
        this.requestSpeciesList()
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        onSelectItem(user) {
            this.modifyMode = false
            this.selectedItem = user.data
        },
        onDeselectItem() {
            this.selectedItem = {}
        },
        reloadGridData() {
            const _this = this
            this.onDeselectItem()
            this.modifyMode = false
            this.gridShow = false
            setTimeout(function () {
                _this.gridShow = true
            }, 50)
        },
        onClickHideButton() {
            this.$vfm.show({ component: RecordHide }, {item: this.selectedItem, refresh: this.$refs.grid.refresh})
        },
        async requestSpeciesList() {
            const response = await this.requestGet(Constants.API.speciesList)
            if (response) {
                this.speciesList = _.orderBy(_.filter(response.species_list || [], species => species.species_type !== '전체'), 'order')
            }
        },
    },
}
</script>