<template>
    <div class="navi">
        <span>{{ title }}</span><template v-if="page"> > <span>{{ page }}</span></template>
    </div>
</template>

<script>

export default {
    name: 'CommonNavigation',
    mixins: [],
    components: {},
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: '-',
            required: true
        },
        page: {
            type: String,
            default: ''
        },
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.2s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
    overflow: hidden;
    max-height: 84px;
}

.slide-enter-from,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>