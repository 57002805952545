<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info" class="pop_wrap">
            <div class="pop_inner04">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">관리팀 정보 수정</p>
                <div class="cont_body  mt15">
                    <div class="table_wrap">
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="15%">
                                <col width="35%">
                                <col width="15%">
                                <col width="35%">
                            </colgroup>
                            <tr>
                                <th>팀 사진</th>
                                <td>
                                    <span class="team_photo">
                                        <img class="pointer" style="width: 100%; height: 100%;" v-if="pictureUrl" alt="image" :src="pictureUrl" @click.prevent="showImageViewer(pictureUrl)"/>
                                    </span>
                                    <input ref="fileUpload" type="file" accept="image/*" @change="handleFileChange" hidden>
                                    <button class="bg blue button xsmall ml10 mb10" type="button" name="button" @click="chooseFile">사진등록</button>
                                </td>
                                <th>관찰대상</th>
                                <td>
                                    <ejs-multiselect
                                        v-if="showMultiselect"
                                        :dataSource="speciesList"
                                        placeholder="관찰대상을 선택해주세요"
                                        :fields="{ text: 'species_type', value: 'species_type' }"
                                        mode="CheckBox"
                                        :allowFiltering="false"
                                        :showClearButton="true"
                                        :showDropDownIcon="true"
                                        :enableSelectionOrder="false"
                                        v-model:value="species_type"
                                        :changeOnBlur="false"
                                    ></ejs-multiselect>
                                </td>
                            </tr>
                            <tr>
                                <th>팀명</th><td><input type="text" name="" class="wp100" v-model="team_name"></td>
                                <th rowspan="5">팀설명</th>
                                <td rowspan="5">
                                    <textarea name="name" rows="12" cols="26" v-model="team_text"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>개설자</th><td><input class="wp100" type="text" name="" readonly v-model="managerEmail"></td>
                            </tr>
                            <tr>
                                <th>개설일</th><td><input class="wp100" type="text" name="" value="2022-03-13 09:10:12" readonly></td>
                            </tr>
                            <tr>
                                <th>팀 기간</th>
                                <td>
                                    <div style="display: flex;">
                                        <div class="wx180">
                                            <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="start_date"></ejs-datepicker>
                                        </div>
                                        &nbsp;&nbsp;~
                                    </div>
                                    <div class="wx180 mt5">
                                        <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="end_date"></ejs-datepicker>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>지역</th>
                                <td class="divide3">
                                    <label class="checklabel wx30">전국
                                        <input type="checkbox" v-model="isAreaAll">
                                        <span class="checkmark"></span>
                                    </label>
                                    <input type="text" name="" class="wx110" v-model="area_addr">
                                    <a href="" @click.prevent="onClickAddressPick"><img src="@/assets/images/location_on.png" alt="" style="width: 24px; height: 24px; vertical-align:middle;"></a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="tac mb20">
                        <button class="bg grey fc white button mid mt30" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg blue button mid mt30 ml5" type="button" name="button" @click="onClickModifyButton">수정하기</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'TeamModify',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            item: {},
            showMultiselect: false,
            team_name: '',
            speciesList: [],
            species_type: [],
            start_date: '',
            end_date: '',
            isAreaAll: true,
            area_addr: '',
            area_lat: '',
            area_lon: '',
            team_text: '',
            picture: '',
            pictureUrl: '',
            doneRefresh: () => {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {
        isAreaAll(val) {
            if (val) {
                this.area_addr = ''
                this.area_lat = ''
                this.area_lon = ''
            }
        },
        species_type: {
            handler(val, oldVal) {
                if (val.length > 0) {
                    if (oldVal.indexOf('전체') < 0 && val.indexOf('전체') >= 0) {
                        this.species_type.length = 0
                        this.species_type.push('전체')
                    } else if (oldVal.indexOf('전체') >= 0 && val.indexOf('전체') >= 0 && val.length > oldVal.length) {
                        this.species_type.splice(this.species_type.indexOf('전체'), 1)
                    }
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.requestSpeciesList()
    },
    methods: {
        setData(params) {
            if (!this.managerEmail) {
                this.showAlert('알림', '로그인 계정 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            if (params && params._value) {
                this.item = params._value.item || {}
                this.doneRefresh = params._value.refresh || (() => {})
            }
            if (!this.item.team_id) {
                this.showAlert('알림', '관찰팀 정보 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            this.team_name = this.item.team_name || ''
            this.species_type.length = 0
            this.species_type.push(...this.item.species_type?.split('|') || [])
            this.start_date = new Date(this.item.start_date_ymd) || ''
            this.end_date = new Date(this.item.end_date_ymd) || ''
            this.isAreaAll = this.item.area === '전국'
            this.area_addr = this.item.area || ''
            this.area_lat = this.item.area || ''
            this.area_lon = this.item.area || ''
            this.team_text = this.item.team_text || ''
            this.picture = ''
            this.pictureUrl = this.item.pic_url || ''
        },
        async requestSpeciesList() {
            const response = await this.requestGet(Constants.API.speciesList)
            if (response) {
                this.speciesList = _.orderBy(_.filter(response.species_list || [], species => species.show_flag === '1'), 'order')
                this.showMultiselect = true
            }
        },
        chooseFile() {
            this.$refs.fileUpload.click()
        },
        handleFileChange(e) {
            let file = e.target.files[0]
            this.picture = file
            if (this.picture) this.pictureUrl = URL.createObjectURL(this.picture)
            else this.pictureUrl = ''
        },
        async onClickAddressPick() {
            new window.daum.Postcode({
                oncomplete: (data) => {
                    let address = ''
                    let extraAddress = ''
                    if (data.userSelectedType === "R") {
                        // 사용자가 도로명 주소를 선택했을 경우
                        address = data.roadAddress;
                    } else {
                        // 사용자가 지번 주소를 선택했을 경우(J)
                        address = data.jibunAddress;
                    }

                    // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                    if (data.userSelectedType === "R") {
                        // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                        // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                        if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
                            extraAddress += data.bname;
                        }
                        // 건물명이 있고, 공동주택일 경우 추가한다.
                        if (data.buildingName !== "" && data.apartment === "Y") {
                            extraAddress +=
                                extraAddress !== ""
                                    ? `, ${data.buildingName}`
                                    : data.buildingName;
                        }
                        // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                        if (extraAddress !== "") {
                            extraAddress = `(${extraAddress})`;
                        }
                    } else {
                        extraAddress = "";
                    }
                    // 우편번호를 입력한다.
                    this.postcode = data.zonecode;
                    this.area_addr = `${address} ${extraAddress}`
                    this.isAreaAll = false
                    this.findLatLon()
                },
            }).open();
        },
        findLatLon() {
            if (!this.area_addr) return
            let geocoder = new window.kakao.maps.services.Geocoder()
            const _this = this
            let callback = function(result, status) {
                if (status === window.kakao.maps.services.Status.OK) {
                    if (result[0]) {
                        _this.area_lat = result[0].y + ''
                        _this.area_lon = result[0].x + ''
                    }
                }
            };
            geocoder.addressSearch(this.area_addr, callback);
        },
        async onClickModifyButton() {
            if (!this.team_name) {
                this.showAlert('알림', '팀명을 입력해주세요.')
                return
            }
            if (!this.team_text) {
                this.showAlert('알림', '팀설명을 입력해주세요.')
                return
            }
            if (!this.start_date || !this.end_date) {
                this.showAlert('알림', '팀 기간을 입력해주세요.')
                return
            }
            let start = moment(this.start_date).format('YYYYMMDD')
            let end = moment(this.end_date).format('YYYYMMDD')
            if (!start || !end) {
                this.showAlert('알림', '팀 기간이 잘못되었습니다.')
                return
            }
            await this.requestTeamModify(close, start, end)
        },
        async requestTeamModify(close, start, end) {
            let data = new FormData()
            data.append('email', this.managerEmail)
            data.append('team_id', this.item.team_id)
            if (this.team_name !== this.item.team_name) data.append('team_name', this.team_name)
            let species_type = this.species_type.join('|')
            if (this.species_type !== this.item.species_type) data.append('species_type', species_type)
            if (this.start_date !== this.item.start_date) data.append('start_date', start)
            if (this.end_date !== this.item.end_date) data.append('end_date', end)
            if (this.team_text !== this.item.team_text) data.append('team_text', this.team_text)
            if (this.area_addr !== this.item.area_addr) {
                data.append('area_addr', this.area_addr || '전국')
                if (this.area_lat) data.append('area_lat', this.area_lat)
                if (this.area_lon) data.append('area_lon', this.area_lon)
            }
            if (this.picture) data.append('picture', this.picture)
            if (this.getFormDataLength(data) < 3) return
            const response = await this.requestFormData(Constants.API.teamModify, data)
            if (response) {
                this.showAlert('알림', '팀 정보 수정이 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
                this.doneRefresh()
            }
        },
    },
}
</script>