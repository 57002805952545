<template>
    <div>
        <div id="container">
            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }}</h2>
                    </div>
                    <!-- search box -->
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="mt35">
                    <p class="mt20 f14 mb20 fc grey">변경하실 비밀번호를 입력해주세요.</p>
                    <input type="password" class="wp100" placeholder="비밀번호(8자 이상, 문자/숫자/기호 사용가능)" v-model="pwd"/>
                    <input type="password" class="wp100 mt10 mb30" placeholder="비밀번호를 다시 입력해 주세요" v-model="pwdConfirm"/>
                    <div class="tac mb30">
                        <button class="bg grey fc white button small" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg blue button small ml5" type="button" name="button" @click="onClickPasswordSetting">변경하기</button>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'

export default {
    name: 'PasswordSettingPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            pageTitle: '비밀번호 재설정',
            authCode: '',
            pwd: '',
            pwdConfirm: '',
        }
    },
    computed: {
    },
    watch: {},
    mounted() {
        this.authCode = this.$route.query?.code || ''
        if (!this.authCode) this.closePopup()
    },
    methods: {
        async onClickPasswordSetting() {
            if (!this.pwd) {
                this.showAlert('알림', '비밀번호를 입력해주세요.')
                return
            }
            if (!this.pwdConfirm) {
                this.showAlert('알림', '비밀번호 확인을 입력해주세요.')
                return
            }
            if (this.pwd !== this.pwdConfirm) {
                this.showAlert('알림', '비밀번호 확인이 잘못되었습니다.')
                return
            }
            await this.requestPasswordSetting()
        },
        async requestPasswordSetting() {
            let params = {
                auth_code: this.authCode,
                pwd: this.pwd,
            }
            const response = await this.requestAppPost(Constants.API.pwdSetting, params)
            if (response) {
                this.showAlert('알림', '비밀번호 재설정이 완료되었습니다.', '확인', () => {
                    this.closePopup()
                })
            }
        },
        closePopup() {
            window.close()
            this.$router.go(-1)
        },
    },
}
</script>