<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info" class="pop_wrap">
            <div class="pop_inner">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">비밀번호 변경</p>
                <div class="cont_body">
                    <p class="mt20 f14 mb20 fc grey">변경하실 비밀번호를 입력해주세요.</p>
                    <input type="password" class="wp100" placeholder="비밀번호(8자 이상, 문자/숫자/기호 사용가능)" v-model="pwd"/>
                    <input type="password" class="wp100 mt10 mb30" placeholder="비밀번호를 다시 입력해 주세요" v-model="pwdConfirm"/>
                    <div class="tac mb30">
                        <button class="bg grey fc white button small" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg blue button small ml5" type="button" name="button" @click="onClickChangeButton">변경하기</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'ChangePassword',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            email: '',
            pwd: '',
            pwdConfirm: '',
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            if (!this.managerEmail) {
                this.showAlert('알림', '로그인 계정 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            if (params && params._value) {
                this.email = params._value.email || ''
            }
            if (!this.email) {
                this.showAlert('알림', '이메일 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
        },
        async onClickChangeButton() {
            if (!this.pwd) {
                this.showAlert('알림', '비밀번호를 입력해주세요.')
                return
            }
            if (!this.pwdConfirm) {
                this.showAlert('알림', '비밀번호 확인을 입력해주세요.')
                return
            }
            if (this.pwd !== this.pwdConfirm) {
                this.showAlert('알림', '비밀번호 확인이 잘못되었습니다.')
                return
            }
            await this.requestChangePwd()
        },
        async requestChangePwd() {
            let params = {
                email: this.email,
                pwd: this.pwd,
            }
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '비밀번호 변경이 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
            }
        },
    },
}
</script>