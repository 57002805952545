<template>
    <div></div>
</template>

<script>
export default {
    name: 'EmptyPage',
    mixins: [],
    components: {},
    data() {
        return {
        }
    },
    computed: {
    },
    watch: {},
    mounted() {
    },
    methods: {
    },
}
</script>