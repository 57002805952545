<template>
    <div v-html="privacy">
    </div>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import _ from "lodash";

export default {
    name: 'PrivacyPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            listData: [],
            privacy: '',
        }
    },
    computed: {
    },
    watch: {},
    mounted() {
        this.reqTermsList()
    },
    methods: {
        async reqTermsList() {
            const _this = this
            const response = await this.requestGet(Constants.API.termsList)
            if (response) {
                this.listData.length = 0
                this.listData.push(..._.orderBy(_.forEach(response.terms_list || [], terms => {
                    if (terms.terms_id === '78bc3441292e4a97a48c8e9f8e268032') _this.privacy = terms.contents
                }), 'order'))
            }
        },
    },
}
</script>