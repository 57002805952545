<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'관찰정보관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }}</h2>
                        <a href="#" class="btn_open bg blue button xsmall ml800" type="button" @click.prevent="onClickTeamRegisterButton">+ 관찰팀 등록</a>
                    </div>
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="divide2">
                    <!-- search box -->
                    <div class="cont_left">
                        <div class="searchbox">
                            <div class="searchcon">
                                <div class="divide3">
                                    팀상태
                                    <select class="wx150 mr40 ml10" name="" v-model="search.status" @change="reloadGridData">
                                        <optgroup label="*** 상태를 선택해주세요 ***">
                                            <option value="">전체</option>
                                            <option value="0">개설중</option>
                                            <option value="1">개설</option>
                                            <option value="2">승인요청</option>
<!--                                            <option value="3">참여중</option>-->
                                        </optgroup>
                                    </select>
                                    팀명<input type="text" name="" class="wx150 mr40" v-model="search.team_name" @keyup.enter="reloadGridData">
                                    개설자<input type="text" name="" class="wx150 mr40" v-model="search.reg_name" @keyup.enter="reloadGridData">
                                </div>
                                <div class="divide3">
                                    <div class="">
                                        검색기간
                                    </div>
                                    <div class="wx230 ml10 mr10">
                                        <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.start_date"></ejs-datepicker>
                                    </div>
                                    <div class="wx230 ml10 mr40">
                                        <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="search.end_date"></ejs-datepicker>
                                    </div>
                                    <div class="ml100">
                                        <button class="bg blue button mid" type="button" name="button" @click="reloadGridData">검색</button><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /search box -->
                        <div class="mt35">
                            <div class="table_header">
                                <div class="dpinline fL">
                                    <div class="f14 pt5" style="padding-top: 7px;">총 {{ listSize || '0' }}건</div>
                                </div>
                            </div>
                            <div class="table_wrap">
                                <ejs-grid
                                    locale="ko-KR"
                                    v-if="gridShow"
                                    ref="grid"
                                    :dataSource="data"
                                    :allowPaging="true"
                                    :pageSettings="pageSettings"
                                    :rowSelected="onSelectItem"
                                    :rowDeselected="onDeselectItem">
                                    <e-columns>
                                        <e-column headerText="관찰팀 사진" textAlign="Center" width=151 :template="'thumbImage'"></e-column>
                                        <e-column field="team_name" headerText="관찰팀 이름" textAlign="Center" width=289></e-column>
                                        <e-column field="owner" headerText="개설자" textAlign="Center" width=98></e-column>
                                        <e-column field="reg_date_ymd" headerText="개설일" textAlign="Center" width=156></e-column>
                                        <e-column field="area" headerText="지역" textAlign="Center" width=75></e-column>
                                        <e-column field="species_type_cs" headerText="대상" textAlign="Center" width=75></e-column>
                                        <e-column field="status_name" headerText="상태" textAlign="Center" width=98></e-column>
                                    </e-columns>
                                    <template v-slot:thumbImage="{data}">
                                        <span style="display: inline-block; width:44px; height:44px; background: #bababa; overflow: hidden;">
                                            <img class="pointer" style="min-width: 100%; min-height: 100%; background-position: center center; background-repeat: no-repeat;" v-if="data.pic_url" :src="data.pic_url" :alt="data.pic_url" @click.prevent="showImageViewer(data.pic_url)">
                                        </span>
                                    </template>
                                </ejs-grid>
                            </div>
                        </div>
                    </div>
                    <div class="cont_right table_wrap">
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="30%">
                                <col width="70%">
                            </colgroup>
                            <tr>
                                <th>관찰팀 사진</th>
                                <td>
                                    <span style="display: inline-block; height:60px; background: #bababa;">
                                        <img class="pointer" style="height:60px;" v-if="selectedItem.pic_url" :src="selectedItem.pic_url" :alt="selectedItem.pic_url" @click.prevent="showImageViewer(selectedItem.pic_url)">
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>관찰팀 이름</th>
                                <td>{{ selectedItem.team_name || '-' }}</td>
                            </tr>
                            <tr>
                                <th>개설자</th>
                                <td class="divide5">
                                    {{ selectedItem.owner || '-' }}
                                    <a v-if="selectedItem.team_name" href="#" class="btn_open bg blue button xsmall" type="button" @click.prevent="onClickTeamModifyButton">관찰팀 정보 수정</a>
                                </td>
                            </tr>
                            <tr>
                                <th>개설일</th>
                                <td>{{ selectedItem.reg_date_full || '-' }}</td>
                            </tr>
                            <tr>
                                <th>팀 기간</th>
                                <td>{{ selectedItem.start_date_ymd || '-' }} {{selectedItem.end_date_ymd ? '~' : ''}} {{ selectedItem.end_date_ymd || '' }}</td>
                            </tr>
                            <tr>
                                <th>지역</th>
                                <td>{{ selectedItem.area || '-' }}</td>
                            </tr>
                            <tr>
                                <th>관찰대상</th>
                                <td>{{ selectedItem.species_type_cs || '-' }}</td>
                            </tr>
                            <tr>
                                <th>상태</th>
                                <td class="divide5">
                                    {{ selectedItem.status_name || '-' }}
                                    <button v-if="selectedItem.status === '2'" class="bg blue button small ml50" type="button" name="button" @click="onClickAcceptButton">관찰팀 승인하기</button>
                                </td>
                            </tr>
                            <tr>
                                <th>미션설명</th>
                                <td>
                                    <textarea name="name" rows="8" cols="50" v-model="selectedItem.team_text" disabled></textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import TeamRegister from './modals/TeamRegister'
import TeamModify from './modals/TeamModify'

export default {
    name: 'ObserveTeamPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        const _this = this
        class PagingListManager extends DataManager {
        }
        class PagingListAdaptor extends UrlAdaptor {
            beforeSend(dataManager, request, ajaxSettings) {
                request.withCredentials = false
                // _this.addPreloaderShowCount()
                try {
                    const page = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.currentPage
                    const row = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.pageSize
                    let param = {
                        page_num: page || 1,
                        row_num: row || 10,
                    }
                    if (_this.search.status) param.status = _this.search.status
                    if (_this.search.team_name) param.team_name = _this.search.team_name
                    if (_this.search.reg_name) param.reg_name = _this.search.reg_name
                    if (_this.search.start_date) {
                        let start = moment(_this.search.start_date).format('YYYYMMDD')
                        if (start) param.start_date = start
                    }
                    if (_this.search.end_date) {
                        let end = moment(_this.search.end_date).format('YYYYMMDD')
                        if (end) param.end_date = end
                    }
                    const data = JSON.stringify(param)
                    ajaxSettings.data = data
                    ajaxSettings.options.data = data
                    // const sessionKey = _this.sessionKey
                    // request.setRequestHeader('session_id', sessionKey)
                } catch (e) {
                    // _this.subPreloaderShowCount()
                }
            }
            processResponse() {
                // _this.subPreloaderShowCount()
                let original = super.processResponse.apply(this, arguments)
                const list = []
                let count = 0
                if (original.resultCode === Constants.API_RESPONSE_CODE.invalidSessionId) {
                    _this.setManagerEmail(undefined)
                    _this.moveTo('Login')
                } else if (original.resultCode !== 200) {
                    _this.showAlert('Alert', original.resultMessage)
                } else {
                    count = Number(original.total_cnt || '0')
                    _.forEach(original.team_list || [], function (item) {
                        item.status_name = item.status === '0' ? '개설중'
                            : item.status === '1' ? '개설'
                            : item.status === '2' ? '승인요청'
                            : item.status === '3' ? '참여중' : '-'
                        item.species_type_cs = item.species_type.replaceAll('|', ', ')
                        if (item.reg_date) {
                            item.reg_date_ymd = _this.dateTimeSetFormat(item.reg_date, 'YYYY-MM-DD')
                            item.reg_date_full = _this.dateTimeSetFormat(item.reg_date)
                        } else {
                            item.reg_date_ymd = '-'
                            item.reg_date_full = '-'
                        }
                        if (item.start_date) {
                            item.start_date_ymd = _this.dateTimeSetFormat(item.start_date, 'YYYY-MM-DD')
                        } else {
                            item.start_date_ymd = '-'
                        }
                        if (item.end_date) {
                            item.end_date_ymd = _this.dateTimeSetFormat(item.end_date, 'YYYY-MM-DD')
                        } else {
                            item.end_date_ymd = '-'
                        }
                        list.push(item)
                    })
                }
                _this.listSize = count
                return { result: list, count: count }
            }
        }
        return {
            pageTitle: '관찰팀 관리',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                status: '',
                team_name: '',
                reg_name: '',
                start_date: '',
                end_date: '',
            },
            pageSettings: { pageSize: 5, pageCount: 5 },
            data: new PagingListManager({
                url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.teamList}`,
                adaptor: new PagingListAdaptor(),
                crossDomain: true
            }),
            selectedItem: {},
            modifyMode: false,
            modifyItem: {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        onSelectItem(user) {
            this.modifyMode = false
            this.selectedItem = user.data
        },
        onDeselectItem() {
            this.selectedItem = {}
        },
        reloadGridData() {
            const _this = this
            this.onDeselectItem()
            this.modifyMode = false
            this.gridShow = false
            setTimeout(function () {
                _this.gridShow = true
            }, 50)
        },
        onClickAcceptButton() {
            if (!this.selectedItem.team_id) return
            this.showConfirm('확인', `[${this.selectedItem.team_name}] 관찰팀을 승인하시겠습니까?`, '확인', async () => {
                await this.reqTeamAccept()
            })
        },
        async reqTeamAccept() {
            let params = {
                email: this.managerEmail,
                team_id: this.selectedItem.team_id,
            }
            const response = await this.requestPost(Constants.API.teamApprove, params)
            if (response) {
                this.showAlert('알림', '관찰팀 승인이 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
        onClickTeamRegisterButton() {
            this.$vfm.show({ component: TeamRegister },{refresh: this.reloadGridData})
        },
        onClickTeamModifyButton() {
            this.$vfm.show({ component: TeamModify }, {item: this.selectedItem, refresh: this.$refs.grid.refresh})
        },
        async onClickModifyButton() {
            if (!this.selectedItem.email) return
            this.modifyItem = _.cloneDeep(this.selectedItem)
            this.modifyMode = true
        },
        async reqManagerModify() {
            if (!this.selectedItem.email) return
            let params = {
                email: this.selectedItem.email,
                member_email: this.selectedItem.email,
            }
            if (this.modifyItem.company && this.modifyItem.company !== this.selectedItem.company) params.company = this.modifyItem.company
            if (this.modifyItem.dept && this.modifyItem.dept !== this.selectedItem.dept) params.dept = this.modifyItem.dept
            if (this.modifyItem.position && this.modifyItem.position !== this.selectedItem.position) params.position = this.modifyItem.position
            if (this.modifyItem.tel && this.modifyItem.tel !== this.selectedItem.tel) params.tel = this.modifyItem.tel
            if (this.modifyItem.mobile && this.modifyItem.mobile !== this.selectedItem.mobile) params.mobile = this.modifyItem.mobile
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '관리자 정보 수정이 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
        async onClickManagerEnable() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.name || this.selectedItem.email}] 관리자롤 활성화 상태로 변경하시겠습니까?`, '확인', async () => {
                await this.reqManagerEnable()
            })
        },
        async reqManagerEnable() {
            let params = {
                email: this.selectedItem.email,
                status: '1',
            }
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '관리자 활성화가 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
        async onClickManagerDisable() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.name || this.selectedItem.email}] 관리자롤 비활성화 상태로 변경하시겠습니까?`, '확인', async () => {
                await this.reqManagerDisable()
            })
        },
        async reqManagerDisable() {
            let params = {
                email: this.selectedItem.email,
                status: '0',
            }
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '관리자 비활성화가 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
    },
}
</script>