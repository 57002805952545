import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
// import {} from '@/mixins'
import {
    EmptyPage,
    LoginPage,
    MemberUserPage,
    MemberManagerPage,
    ObserveRecordPage,
    ObserveTeamPage,
    InfoCategoryPage,
    InfoGradePage,
    NoticePage,
    HelpPage,
    InquiryPage,
    TermsPage,
    StatisticsPage,
    VersionPage,
    PasswordSettingPage,
    PrivacyPage,
    UseTermsPage,
} from '@/views'

const routes = [
    {
        path: '/',
        name: 'Empty',
        component: EmptyPage,
    },
    {
        path: '/mgr/0123/login',
        name: 'Login',
        component: LoginPage,
    },
    {
        path: '/mgr/0123/member/user',
        name: 'MemberUser',
        component: MemberUserPage,
    },
    {
        path: '/mgr/0123/member/manager',
        name: 'MemberManager',
        component: MemberManagerPage,
    },
    {
        path: '/mgr/0123/observe/record',
        name: 'ObserveRecord',
        component: ObserveRecordPage,
    },
    {
        path: '/mgr/0123/observe/team',
        name: 'ObserveTeam',
        component: ObserveTeamPage,
    },
    {
        path: '/mgr/0123/info/category',
        name: 'InfoCategory',
        component: InfoCategoryPage,
    },
    {
        path: '/mgr/0123/info/grade',
        name: 'InfoGrade',
        component: InfoGradePage,
    },
    {
        path: '/mgr/0123/notice',
        name: 'Notice',
        component: NoticePage,
    },
    {
        path: '/mgr/0123/help',
        name: 'Help',
        component: HelpPage,
    },
    {
        path: '/mgr/0123/inquiry',
        name: 'Inquiry',
        component: InquiryPage,
    },
    {
        path: '/mgr/0123/terms',
        name: 'Terms',
        component: TermsPage,
    },
    {
        path: '/mgr/0123/statistics',
        name: 'Statistics',
        component: StatisticsPage,
    },
    {
        path: '/mgr/0123/version',
        name: 'Version',
        component: VersionPage,
    },
    {
        path: '/pwd/reset',
        name: 'PasswordSetting',
        component: PasswordSettingPage,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyPage,
    },
    {
        path: '/useTerms',
        name: 'UseTerms',
        component: UseTermsPage,
    },
    {
        path: '/:catchAll(.*)*',
        redirect: '/',
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    console.log(to.name)
    if (to.name === 'Empty' || to.name === 'Login' || to.name === 'PasswordSetting' || to.name === 'Privacy' || to.name === 'UseTerms') {
        next()
    } else {
        const email = store.getters.managerEmail || ''
        if (!email) {
            next({name: 'Login'})
        } else {
            next()
        }
    }
})

export default router