<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="pop_wrap" content-class="pop_inner05" :click-to-close="true" @beforeOpen="event => setData(event.ref.params)">
        <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
        <div class="cont_head">이미지 상세보기</div>
        <!-- /cont_head -->
        <!-- cont_body -->
        <div class="cont_body">
            <img style="min-width: 100%; min-height: 100%; max-height: 100%; background-position: center center; background-repeat: no-repeat;" :src="src">
        </div>
    </vue-final-modal>
</template>

<script>
export default {
    name: 'ImageViewer',
    mixins: [],
    components: {},
    data() {
        return {
            src: '',
        }
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            if (params && params._value) {
                if (params._value.src) this.src = params._value.src
            }
        },
    },
}
</script>