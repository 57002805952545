<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false" @beforeOpen="event => setData(event.ref.params)">
        <div id="pop_info" class="pop_wrap">
            <div class="pop_inner04">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">공지사항 수정</p>
                <div class="cont_body mt30">
                    <div class="table_wrap">
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="20%">
                                <col width="80%">
                            </colgroup>
                            <tr>
                                <th>제목</th><td><input type="text" name="" v-model="title" class="wp100"></td>
                            </tr>
                            <tr>
                                <th>공지기간</th>
                                <td>
                                    <div style="display: flex;">
                                        <div class="wx180">
                                            <ejs-datepicker class="wx120" id="startdatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="start_date"></ejs-datepicker>
                                        </div>
                                        &nbsp;&nbsp;~&nbsp;&nbsp;
                                        <div class="wx180">
                                            <ejs-datepicker class="wx120" id="enddatepicker" placeholder="YYYY-MM-DD" format="yyyy-MM-dd" v-model:value="end_date"></ejs-datepicker>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <textarea name="name" rows="11" cols="65" v-model="contents"></textarea>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="tac mb20">
                        <button class="bg grey fc white button mid mt30" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg blue button mid mt30 ml5" type="button" name="button" @click="onClickModifyButton">수정하기</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import moment from 'moment'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters} from 'vuex'

export default {
    name: 'NoticeModify',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            item: {},
            title: '',
            contents: '',
            start_date: '',
            end_date: '',
            doneRefresh: () => {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        setData(params) {
            if (!this.managerEmail) {
                this.showAlert('알림', '로그인 계정 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            if (params && params._value) {
                this.item = params._value.item || {}
                this.doneRefresh = params._value.refresh || (() => {})
            }
            if (!this.item.notify_id) {
                this.showAlert('알림', '공지사항 정보 확인에 실패했습니다.')
                this.$vfm.hide(this.$options.name)
            }
            this.title = this.item.title
            this.contents = this.item.contents
            this.start_date = this.item.start_date_ymd
            this.end_date = this.item.end_date_ymd
        },
        async onClickModifyButton() {
            if (!this.title) {
                this.showAlert('알림', '제목을 입력해주세요.')
                return
            }
            if (!this.start_date || !this.end_date) {
                this.showAlert('알림', '공지 기간을 입력해주세요.')
                return
            }
            let start = moment(this.start_date).format('YYYYMMDD')
            let end = moment(this.end_date).format('YYYYMMDD')
            if (!start || !end) {
                this.showAlert('알림', '공지 기간이 잘못되었습니다.')
                return
            }
            if (!this.contents) {
                this.showAlert('알림', '내용을 입력해주세요.')
                return
            }
            await this.requestNoticeModify(close, start, end)
        },
        async requestNoticeModify(close, start, end) {
            let params = {
                email: this.managerEmail,
                notify_id: this.item.notify_id,
            }
            if (this.title !== this.item.title) params.title = this.title
            if (this.contents !== this.item.contents) params.contents = this.contents
            if (start !== this.item.start_date) params.start_date = start
            if (end !== this.item.end_date) params.end_date = end
            if (this.getObjectLength(params) < 3) {
                this.$vfm.hide(this.$options.name)
                return
            }
            const response = await this.requestPost(Constants.API.notifyModify, params)
            if (response) {
                this.showAlert('알림', '공지사항 수정이 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
                this.doneRefresh()
            }
        },
    },
}
</script>