<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content" :click-to-close="false">
        <div id="pop_info" class="pop_wrap">
            <div class="pop_inner">
                <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
                <p class="cont_head">비밀번호 찾기</p>
                <div class="cont_body">
                    <p class="mt20 f14 mb30 fc grey">가입하신 회원 계정의 이메일 주소를 입력해 주세요.
                        입력하신 이메일 주소, 정보로 비밀번호 재설정 링크를 보내드리겠습니다.<br><br>

                        수신한 이메일에서 링크를 확인하여 비밀번호를 재설정하세요.</p>
                    <input type="text" class="wp100 mb30" placeholder="이메일 주소를 입력해주세요" v-model="email"/>
                    <div class="tac mb30">
                        <button class="bg grey fc white button small" type="button" name="button" @click="close">취소하기</button>
                        <button class="bg blue button small ml5" type="button" name="button" @click="onClickResetButton">비밀번호 재설정</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'

export default {
    name: 'FindPassword',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            email: ''
        }
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        async onClickResetButton() {
            if (!this.email) {
                this.showAlert('알림', '이메일 주소를 입력해주세요.')
                return
            }
            if (!this.isEmailValid(this.email)) {
                this.showAlert('알림', '이메일 형식이 잘못되었습니다.')
                return
            }
            await this.requestFindPwd()
        },
        async requestFindPwd() {
            let params = {
                email: this.email,
            }
            const response = await this.requestPost(Constants.API.accountReset, params)
            if (response) {
                this.showAlert('알림', '메일 발송이 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
            }
        },
    },
}
</script>