<template>
    <div id="header">
        <div class="top_menu">
            <div class="logo_area">
                <a href="#"><img src="@/assets/images/eco_logo.png" alt="ecosee" style="width: 165px; height: 31px"></a>
                <ul class="util">
                    <li>
                        <i class="fas fa-user-cog"></i>{{ this.managerEmail }} 님
                    </li>
                    <li>
                        {{ nowTime }}
                    </li>
                    <li>
                        <a href="#" class="logout" @click.prevent="logout"><i class="fas fa-sign-out-alt"></i>로그아웃</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import {mapGetters, mapMutations} from 'vuex'

export default {
    name: 'CommonHeader',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            now: new Date(),
            timerId: 0,
        }
    },
    computed: {
        ...mapGetters(['managerEmail']),
        nowTime () {
            return moment(this.now).format('YYYY-MM-DD HH:mm:ss')
        },
    },
    watch: {},
    mounted() {
        this.timerId = setInterval(this.updateTimer, 500)
    },
    unmounted() {
        if (this.timerId) {
            clearInterval(this.timerId)
        }
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        updateTimer() {
            this.now = new Date()
        },
        logout() {
            this.setManagerEmail(undefined)
            this.moveTo('Login')
        },
    },
}
</script>