<template>
    <div>
        <common-header/>
        <div id="container">
            <common-menu/>

            <div id="content" class="srcform">
                <!-- cont_head -->
                <div class="cont_head">
                    <common-navigation :title="'회원관리'" :page="pageTitle"/>
                    <div class="title">
                        <h2 class="h2">{{ pageTitle }}</h2>
                    </div>
                    <!-- search box -->
                    <div class="searchbox">
                        <div class="searchcon">
                            <div class="">
                                회사<input type="text" name="" data-pcwid="200" class="wx200 mr40" v-model="search.company" @keyup.enter="reloadGridData">
                                이메일<input type="text" name="" data-pcwid="200" class="wx200 mr40" v-model="search.email" @keyup.enter="reloadGridData">
                                이름<input type="text" name="" data-pcwid="200" class="wx1200 mr40" v-model="search.name" @keyup.enter="reloadGridData">
                                회원상태
                                <select class="wx200 mr40" name="" v-model="search.status" @change="reloadGridData">
                                    <optgroup label="*** 상태를 선택해주세요 ***">
                                        <option value="">전체</option>
                                        <option value="1">활성화</option>
                                        <option value="0">비활성화</option>
                                    </optgroup>
                                </select>
                                <button class="bg blue button mid mr200" type="button" name="button" @click="reloadGridData">검색</button>
                            </div>
                        </div>
                    </div>
                    <!-- /search box -->
                </div>
                <!-- /cont_head -->
                <!-- cont_body -->
                <div class="divide">
                    <div class="cont_left">
                        <div class="table_header">
                            <div class="dpinline fL">
                                <div class="f14 pt5" style="padding-top: 7px;">총 {{ listSize || '0' }}건</div>
                            </div>
                        </div>
                        <div class="table_wrap">
                            <ejs-grid
                                locale="ko-KR"
                                v-if="gridShow"
                                ref="grid"
                                :dataSource="data"
                                :allowPaging="true"
                                :pageSettings="pageSettings"
                                :rowSelected="onSelectItem"
                                :rowDeselected="onDeselectItem">
                                <e-columns>
                                    <e-column field="name" headerText="이름" textAlign="Center" width=67></e-column>
                                    <e-column field="email" headerText="이메일" textAlign="Center" width=196></e-column>
                                    <e-column field="company" headerText="회사명" textAlign="Center" width=78></e-column>
                                    <e-column field="dept" headerText="부서" textAlign="Center" width=99></e-column>
                                    <e-column field="position" headerText="직급" textAlign="Center" width=67></e-column>
                                    <e-column field="status_name" headerText="상태" textAlign="Center" width=67></e-column>
                                    <e-column field="tel" headerText="전화번호" textAlign="Center" width=126></e-column>
                                    <e-column field="mobile" headerText="휴대폰" textAlign="Center" width=135></e-column>
                                    <e-column field="reg_date_ymd" headerText="가입일자" textAlign="Center" width=106></e-column>
                                </e-columns>
                            </ejs-grid>
                        </div>
                    </div>
                    <div class="cont_right table_wrap">
                        <table class="tbl_basic">
                            <colgroup>
                                <col width="30%">
                                <col width="70%">
                            </colgroup>

                            <tr>
                                <th>이름</th>
                                <td class="divide5">
                                    {{ selectedItem.name || '-' }}
                                    <a v-if="selectedItem.email && selectedItem.email === managerEmail" href="#" class="btn_open bg blue button xsmall" type="button" @click.prevent="onClickPwdChangeButton(selectedItem.email)">비밀번호 변경</a>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td>{{ selectedItem.email || '-' }}</td>
                            </tr>
                            <tr>
                                <th>가입일자</th>
                                <td>{{ selectedItem.reg_date_full || '-' }}</td>
                            </tr>
                            <tr>
                                <th>회사명</th>
                                <td v-if="modifyMode">
                                    <input type="text" class="wp100" :placeholder="selectedItem.company" v-model="modifyItem.company"/>
                                </td>
                                <td v-else>{{ selectedItem.company || '-' }}</td>
                            </tr>
                            <tr>
                                <th>부서</th>
                                <td v-if="modifyMode">
                                    <input type="text" class="wp100" :placeholder="selectedItem.dept" v-model="modifyItem.dept"/>
                                </td>
                                <td v-else>{{ selectedItem.dept || '-' }}</td>
                            </tr>
                            <tr>
                                <th>직급</th>
                                <td v-if="modifyMode">
                                    <input type="text" class="wp100" :placeholder="selectedItem.position" v-model="modifyItem.position"/>
                                </td>
                                <td v-else>{{ selectedItem.position || '-' }}</td>
                            </tr>
                            <tr>
                                <th>상태</th>
                                <td class="divide5">
                                    {{ selectedItem.status_name || '-' }}
                                    <button v-if="selectedItem.status === '0'" @click="onClickManagerEnable" class="bg blue button mid fR" type="button" name="button">관리자 활성화 하기</button>
                                    <button v-if="selectedItem.status === '1'" @click="onClickManagerDisable" class="bg grey button mid fR" type="button" name="button">관리자 비활성화 하기</button>
                                </td>
                            </tr>
                            <tr>
                                <th>전화번호</th>
                                <td v-if="modifyMode">
                                    <input type="text" class="wp100" :placeholder="selectedItem.tel" v-model="modifyItem.tel"/>
                                </td>
                                <td v-else>{{ selectedItem.tel || '-' }}</td>
                            </tr>
                            <tr>
                                <th>휴대폰</th>
                                <td v-if="modifyMode">
                                    <input type="text" class="wp100" :placeholder="selectedItem.mobile" v-model="modifyItem.mobile"/>
                                </td>
                                <td v-else>{{ selectedItem.mobile || '-' }}</td>
                            </tr>
                        </table>
                        <div class="tac mb20">
                            <button v-if="selectedItem.email && !modifyMode" class="bg blue button mid mt30" type="button" name="button" @click="onClickModifyButton">수정하기</button>
                            <button v-if="selectedItem.email && modifyMode" class="bg grey button mid mt30" type="button" name="button" @click="modifyMode = false">취소하기</button>
                            <button v-if="selectedItem.email && modifyMode" class="bg blue button mid mt30 ml5" type="button" name="button" @click="reqManagerModify">저장하기</button>
                        </div>
                    </div>
                </div>
                <!-- /cont_body -->
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import CommonHeader from '@/components/CommonHeader'
import CommonMenu from '@/components/CommonMenu'
import CommonNavigation from '@/components/CommonNavigation'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'
import {mapGetters, mapMutations} from 'vuex'
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'
import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data'
import ChangePassword from './modals/ChangePassword'

export default {
    name: 'MemberManagerPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {
        CommonHeader,
        CommonMenu,
        CommonNavigation,
        'ejs-grid' : GridComponent,
        'e-columns' : ColumnsDirective,
        'e-column' : ColumnDirective,
    },
    data() {
        const _this = this
        class PagingListManager extends DataManager {
        }
        class PagingListAdaptor extends UrlAdaptor {
            beforeSend(dataManager, request, ajaxSettings) {
                request.withCredentials = false
                // _this.addPreloaderShowCount()
                try {
                    const page = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.currentPage
                    const row = _this.$refs.grid.ej2Instances.pagerModule.pageSettings.properties.pageSize
                    let param = {
                        page_num: page || 1,
                        row_num: row || 10,
                    }
                    if (_this.search.company) param.company = _this.search.company
                    if (_this.search.email) param.email = _this.search.email
                    if (_this.search.name) param.name = _this.search.name
                    if (_this.search.status) param.status = _this.search.status
                    const data = JSON.stringify(param)
                    ajaxSettings.data = data
                    ajaxSettings.options.data = data
                    // const sessionKey = _this.sessionKey
                    // request.setRequestHeader('session_id', sessionKey)
                } catch (e) {
                    // _this.subPreloaderShowCount()
                }
            }
            processResponse() {
                // _this.subPreloaderShowCount()
                let original = super.processResponse.apply(this, arguments)
                const list = []
                let count = 0
                if (original.resultCode === Constants.API_RESPONSE_CODE.invalidSessionId) {
                    _this.setManagerEmail(undefined)
                    _this.moveTo('Login')
                } else if (original.resultCode !== 200) {
                    _this.showAlert('Alert', original.resultMessage)
                } else {
                    count = Number(original.total_cnt || '0')
                    _.forEach(original.manager_list || [], function (item) {
                        item.status_name = item.status === '1' ? '활성화' : item.status === '0' ? '비활성화' : '-'
                        if (item.reg_date) {
                            item.reg_date_ymd = _this.dateTimeSetFormat(item.reg_date, 'YYYY-MM-DD')
                            item.reg_date_full = _this.dateTimeSetFormat(item.reg_date)
                        } else {
                            item.reg_date_ymd = '-'
                            item.reg_date_full = '-'
                        }
                        list.push(item)
                    })
                }
                _this.listSize = count
                return { result: list, count: count }
            }
        }
        return {
            pageTitle: '관리자 회원관리',
            listSize: 0,
            pageNum: 1,
            gridShow: true,
            search: {
                company: '',
                email: '',
                name: '',
                status: '',
            },
            pageSettings: { pageSize: 10, pageCount: 5 },
            data: new PagingListManager({
                url: `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PREFIX}${Constants.API.managerList}`,
                adaptor: new PagingListAdaptor(),
                crossDomain: true
            }),
            selectedItem: {},
            modifyMode: false,
            modifyItem: {},
        }
    },
    computed: {
        ...mapGetters(['managerEmail'])
    },
    watch: {},
    mounted() {
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        onSelectItem(user) {
            this.modifyMode = false
            this.selectedItem = user.data
        },
        onDeselectItem() {
            this.selectedItem = {}
        },
        reloadGridData() {
            const _this = this
            this.onDeselectItem()
            this.modifyMode = false
            this.gridShow = false
            setTimeout(function () {
                _this.gridShow = true
            }, 50)
        },
        onClickPwdChangeButton(email) {
            this.$vfm.show({ component: ChangePassword }, {email: email})
        },
        async onClickModifyButton() {
            if (!this.selectedItem.email) return
            this.modifyItem = _.cloneDeep(this.selectedItem)
            this.modifyMode = true
        },
        async reqManagerModify() {
            if (!this.selectedItem.email) return
            let params = {
                email: this.selectedItem.email,
                member_email: this.selectedItem.email,
            }
            if (this.modifyItem.company && this.modifyItem.company !== this.selectedItem.company) params.company = this.modifyItem.company
            if (this.modifyItem.dept && this.modifyItem.dept !== this.selectedItem.dept) params.dept = this.modifyItem.dept
            if (this.modifyItem.position && this.modifyItem.position !== this.selectedItem.position) params.position = this.modifyItem.position
            if (this.modifyItem.tel && this.modifyItem.tel !== this.selectedItem.tel) params.tel = this.modifyItem.tel
            if (this.modifyItem.mobile && this.modifyItem.mobile !== this.selectedItem.mobile) params.mobile = this.modifyItem.mobile
            if (this.getObjectLength(params) < 3) return
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '관리자 정보 수정이 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
        async onClickManagerEnable() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.name || this.selectedItem.email}] 관리자롤 활성화 상태로 변경하시겠습니까?`, '확인', async () => {
                await this.reqManagerEnable()
            })
        },
        async reqManagerEnable() {
            let params = {
                email: this.selectedItem.email,
                status: '1',
            }
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '관리자 활성화가 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
        async onClickManagerDisable() {
            if (!this.selectedItem.email) return
            this.showConfirm('확인', `[${this.selectedItem.name || this.selectedItem.email}] 관리자롤 비활성화 상태로 변경하시겠습니까?`, '확인', async () => {
                await this.reqManagerDisable()
            })
        },
        async reqManagerDisable() {
            let params = {
                email: this.selectedItem.email,
                status: '0',
            }
            const response = await this.requestPost(Constants.API.managerModify, params)
            if (response) {
                this.showAlert('알림', '관리자 비활성화가 완료되었습니다.')
                this.modifyMode = false
                this.$refs.grid.refresh()
            }
        },
    },
}
</script>