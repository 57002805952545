<template>
    <div id="login_wrap">
        <!-- login_box -->
        <div class="login_box">
            <div class="login_titlow"><h2 class="login_tit">management sytem</h2></div>
            <div class="login_cont">
                <p class="mb10">ID</p>
                <input type="text" class="wp100 mb20" placeholder="이메일 주소를 입력해주세요" v-model="email"/>
                <div class="valid_warn" v-if="emailNotEntered" style="display: block">이메일 주소를 입력해주세요.</div>
                <div class="valid_warn" v-if="emailNotValid" style="display: block">이메일 주소 형식이 잘못되었습니다.</div>
                <p class="mb10">Password</p>
                <input type="password" class="wp100 mb20" placeholder="비밀번호를 입력해주세요." v-model="pwd" @keyup.enter="onClickLoginButton">
                <div class="valid_warn" v-if="pwdNotEntered" style="display: block">비밀번호를 입력해주세요.</div>
                <div class="idpass">
                    <label class="checklabel wx70">아이디 저장
                        <input type="checkbox" v-model="emailSaveCheck"/>
                        <span class="checkmark"></span>
                    </label>
                    <a href="#" class="btn_open" @click.prevent="onClickFindPasswordButton">비밀번호 찾기</a>
                </div>
                <div class="tac">
                    <button class="bg black button large mt40 mb40 wp100" type="button" name="button" @click="onClickLoginButton">로그인</button>
                </div>
                <p class="tac f14">계정이 없으세요? <a href="#" class="btn_open fc blue" @click.prevent="onClickAccountRegisterButton">관리자계정 등록하기</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import AccountRegister from '@/views/01-login/modals/AccountRegister'
import FindPassword from '@/views/01-login/modals/FindPassword'
import Constants from '@/common/constants'
import { mapMutations } from 'vuex'

export default {
    name: 'LoginPage',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            email: '',
            pwd: '',
            emailNotEntered: false,
            emailNotValid: false,
            pwdNotEntered: false,
            emailSaveCheck: false,
        }
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        ...mapMutations(['setManagerEmail']),
        async onClickLoginButton() {
            let email = this.email
            let pwd = this.pwd
            this.emailNotEntered = !email
            this.pwdNotEntered = !pwd
            if (!this.emailNotEntered) {
                this.emailNotValid = !this.isEmailValid(email)
            }
            if (this.emailNotEntered || this.pwdNotEntered || this.emailNotValid) {
                return
            }
            await this.requestLogin(email, pwd)
        },
        async requestLogin() {
            let params = {
                email: this.email,
                pwd: this.pwd,
            }
            const response = await this.requestPost(Constants.API.accountLogin, params, true)
            console.log(response)
            if (!response) {
                this.showAlert('알림', `로그인에 실패했습니다.`)
            } else if (response.resultCode === 200) {
                this.setManagerEmail(this.email)
                if (this.emailSaveCheck) {
                    // todo save email address
                }
                this.moveTo('MemberUser')
            } else if (response.resultCode === 404 || response.resultCode === 1002) {
                this.showAlert('알림', `로그인에 실패했습니다.\n아이디 혹은 패스워드를 확인해주세요.`)
            } else {
                this.showAlert('알림', response.resultMessage || `로그인에 실패했습니다.`)
            }
        },
        onClickFindPasswordButton() {
            this.$vfm.show({ component: FindPassword })
        },
        onClickAccountRegisterButton() {
            this.$vfm.show({ component: AccountRegister })
        },
    },
}
</script>