<template>
    <vue-final-modal :name="$options.name" v-slot="{ close }" v-bind="$attrs" classes="pop_wrap" content-class="pop_inner03" :click-to-close="false">
        <button type="button" class="btn_close mr20 mt10" @click="close">닫기</button>
        <div class="cont_head">관리자 등록</div>
        <!-- /cont_head -->
        <!-- cont_body -->
        <div class="cont_body mt30">
            <div class="table_wrap">
                <table class="table_list">
                    <colgroup>
                        <col width="30%">
                        <col width="70%">
                    </colgroup>
                    <tr class="head">
                    </tr>
                    <tr>
                        <td>이메일</td>
                        <td>
                            <input type="text" class="wp100" placeholder="이메일 주소를 입력해주세요" v-model="email"/>
                        </td>
                    </tr>
                    <tr>
                        <td>비밀번호</td>
                        <td>
                            <input type="password" class="wp100" placeholder="비밀번호(8자 이상, 문자/숫자/기호 사용가능)" v-model="pwd"/>
                        </td>
                    </tr>
                    <tr>
                        <td>비밀번호 확인</td>
                        <td>
                            <input type="password" class="wp100" placeholder="비밀번호를 다시 입력해 주세요" v-model="pwdConfirm"/>
                        </td>
                    </tr>
                    <tr>
                        <td>이름</td>
                        <td>
                            <input type="text" class="wp100" placeholder="이름 또는 닉네임을 입력해 주세요" v-model="name"/>
                        </td>
                    </tr>
                    <tr>
                        <td>회사명</td>
                        <td>
                            <input type="text" class="wp100" placeholder="회사명을 입력해 주세요" v-model="company"/>
                        </td>
                    </tr>
                    <tr>
                        <td>부서</td>
                        <td>
                            <input type="text" class="wp100" placeholder="부서명을 입력해 주세요" v-model="dept"/>
                        </td>
                    </tr>
                    <tr>
                        <td>직급</td>
                        <td>
                            <input type="text" class="wp100" placeholder="직급 정보를 입력해 주세요" v-model="position"/>
                        </td>
                    </tr>
                    <tr>
                        <td>전화번호</td>
                        <td>
                            <input type="text" class="wp100" placeholder="전화번호를 입력해 주세요" v-model="tel"/>
                        </td>
                    </tr>
                    <tr>
                        <td>휴대폰</td>
                        <td>
                            <input type="text" class="wp100" placeholder="휴대전화번호 정보를 입력해 주세요" v-model="mobile"/>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="tac mb20">
                <button class="bg grey fc white button mid mt30" type="button" name="button" @click="close">취소하기</button>
                <button class="bg blue button mid mt30 ml5" type="button" name="button" @click="onClickRegisterButton">등록하기</button>
            </div>
        </div>
    </vue-final-modal>
</template>

<script>
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import Constants from '@/common/constants'

export default {
    name: 'AccountRegister',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            email: '',
            pwd: '',
            pwdConfirm: '',
            name: '',
            company: '',
            dept: '',
            position: '',
            tel: '',
            mobile: '',
        }
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {
        async onClickRegisterButton() {
            if (!this.email) {
                this.showAlert('알림', '이메일 주소를 입력해주세요.')
                return
            }
            if (!this.isEmailValid(this.email)) {
                this.showAlert('알림', '이메일 형식이 잘못되었습니다.')
                return
            }
            if (!this.pwd) {
                this.showAlert('알림', '비밀번호를 입력해주세요.')
                return
            }
            if (!this.pwdConfirm) {
                this.showAlert('알림', '비밀번호 확인을 입력해주세요.')
                return
            }
            if (this.pwd !== this.pwdConfirm) {
                this.showAlert('알림', '비밀번호 확인이 잘못 입력되었습니다.')
                return
            }
            if (!this.name) {
                this.showAlert('알림', '이름을 입력해주세요.')
                return
            }
            if (!this.mobile) {
                this.showAlert('알림', '휴대폰 번호를 입력해주세요.')
                return
            }
            await this.requestRegister()
        },
        async requestRegister() {
            let params = {
                email: this.email,
                pwd: this.pwd,
                name: this.name,
                mobile: this.mobile,
            }
            if (this.company) params.company = this.company
            if (this.dept) params.dept = this.dept
            if (this.position) params.position = this.position
            if (this.tel) params.tel = this.tel
            const response = await this.requestPost(Constants.API.accountRegister, params)
            if (response) {
                this.showAlert('알림', '회원 등록이 완료되었습니다.')
                this.$vfm.hide(this.$options.name)
            }
        },
    },
}
</script>