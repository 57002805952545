import Axios from 'axios'
import { Service } from 'axios-middleware'
import Store from '@/store'
import Constants from '@/common/constants'

const service = new Service(Axios)
service.register({
    onRequest(config) {
        if (config.url.indexOf(Constants.API.statExcel) >= 0) {
            config.headers = Object.assign({}, config.headers, { responseType: 'blob' })
        }
        let sessionKey = Store.getters.sessionKey
        if (sessionKey) config.headers = Object.assign({}, config.headers, { session_id: sessionKey })
        return config
    },
    onResponse(response) {
        return response
    }
})

const instance = Axios.create({
    baseUrl: process.env.VUE_APP_API_URL,
    withCredentials: false
})

export default instance