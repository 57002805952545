<template>
    <div id="gnb">
        <button class="accordion" :class="{ active: isShowFirst }" @click="isShowFirst = !isShowFirst">회원관리</button>
        <transition name="slide">
            <ul class="panel" v-show="isShowFirst">
                <li @click.prevent="moveTo('MemberUser')">
                    <a href="#" :class="{ active: $route.name === 'MemberUser' }">사용자 회원관리</a>
                </li>
                <li @click.prevent="moveTo('MemberManager')">
                    <a href="#" :class="{ active: $route.name === 'MemberManager' }">관리자 회원관리</a>
                </li>
            </ul>
        </transition>
        <button class="accordion" :class="{ active: isShowSecond }" @click="isShowSecond = !isShowSecond">관찰정보 관리</button>
        <transition name="slide">
            <ul class="panel" v-show="isShowSecond">
                <li @click.prevent="moveTo('ObserveRecord')">
                    <a href="#" :class="{ active: $route.name === 'ObserveRecord' }">관찰기록 관리</a>
                </li>
                <li @click.prevent="moveTo('ObserveTeam')">
                    <a href="#" :class="{ active: $route.name === 'ObserveTeam' }">관찰팀 관리</a>
                </li>
            </ul>
        </transition>
        <button class="accordion" :class="{ active: isShowThird }" @click="isShowThird = !isShowThird">정보관리</button>
        <transition name="slide">
            <ul class="panel" v-show="isShowThird">
                <li @click.prevent="moveTo('InfoCategory')">
                    <a href="#" :class="{ active: $route.name === 'InfoCategory' }">생물유형 관리</a>
                </li>
                <li @click.prevent="moveTo('InfoGrade')">
                    <a href="#" :class="{ active: $route.name === 'InfoGrade' }">회원등급 관리</a>
                </li>
            </ul>
        </transition>
        <button class="accordion02" :class="{ active: $route.name === 'Notice' }" @click.prevent="moveTo('Notice')">공지사항 관리</button>
        <button class="accordion02" :class="{ active: $route.name === 'Help' }" @click.prevent="moveTo('Help')">도움말 관리</button>
        <button class="accordion02" :class="{ active: $route.name === 'Inquiry' }" @click.prevent="moveTo('Inquiry')">문의사항 관리</button>
        <button class="accordion02" :class="{ active: $route.name === 'Terms' }" @click.prevent="moveTo('Terms')">약관 및 정책 관리</button>
        <button class="accordion02" :class="{ active: $route.name === 'Statistics' }" @click.prevent="moveTo('Statistics')">통계</button>
        <button class="accordion02" :class="{ active: $route.name === 'Version' }" @click.prevent="moveTo('Version')">APP버전관리</button>
    </div>
</template>

<script>
import moment from 'moment'
import { CommonFunctions, ModalFunctions, NetworkFunctions } from '@/mixins'
import { mapGetters } from 'vuex'

export default {
    name: 'CommonMenu',
    mixins: [CommonFunctions, ModalFunctions, NetworkFunctions],
    components: {},
    data() {
        return {
            now: new Date(),
            timerId: 0,
            isShowFirst: false,
            isShowSecond: false,
            isShowThird: false,
        }
    },
    computed: {
        ...mapGetters(['managerEmail']),
        nowTime () {
            return moment(this.now).format('YYYY-MM-DD HH:mm:ss')
        },
    },
    watch: {},
    mounted() {
        if (this.$route.name === 'MemberUser' || this.$route.name === 'MemberManager') this.isShowFirst = true
        if (this.$route.name === 'ObserveRecord' || this.$route.name === 'ObserveTeam') this.isShowSecond = true
        if (this.$route.name === 'InfoCategory' || this.$route.name === 'InfoGrade') this.isShowThird = true
        this.timerId = setInterval(this.updateTimer, 500)
    },
    unmounted() {
        if (this.timerId) {
            clearInterval(this.timerId)
        }
    },
    methods: {
        updateTimer() {
            this.now = new Date()
        }
    },
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.2s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
    overflow: hidden;
    max-height: 84px;
}

.slide-enter-from,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>